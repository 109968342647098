<div *ngIf="loaded===false">
  <app-loading></app-loading>
</div>

<div *ngIf="loaded===true">
  <app-header [cmsMainSection]=cmsHeader [cultureSelected]=cultureKentico></app-header>
</div>

<div *ngIf="loaded===true && modal===true && (!msgStc || msgStc && msgStc === '')">
  <app-refund-modal-not-found [texto]=msg  [cultureKentico]=cultureKentico></app-refund-modal-not-found>
</div>

<div *ngIf="loaded===true">
  <app-full-page class='full-page' [textoStc]=msgStc>
    
    <div class="col-12 text-md-center text-center mb-5" *ngIf="!msgStc.includes('error') && msgStc != 'success_msg_static_refund_confirmation'">
      <button *ngIf="!msgStc.includes('error') && msgStc != 'success_msg_static_refund_confirmation'" type="button" (click)="getCode()"name="submitRefunds" class="img__btn mt-3" >
        <img src={{cmsMainSection.btn_confirmar[0].url}}>
      </button>
    </div>
    
  </app-full-page>
</div>

<!--<div *ngIf="loaded===true && (!msgStc || (msgStc && msgStc === ''))">-->
  <!--<app-refund-search *ngIf="" [cmsMainSection]=cmsMainSection></app-refund-search>-->
<!--</div>-->

<div *ngIf="loaded===true">
  <app-footer [cmsMainSection]=cmsFooter></app-footer>
</div>