import {
  Component, OnInit, Input, SimpleChanges, OnChanges, Output,
  EventEmitter, OnDestroy, ElementRef, ViewChild, ChangeDetectorRef
} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, UntypedFormArray, UntypedFormControl, Validators} from '@angular/forms';
import {RefundService} from '../refund.service';
import {ReCaptcha2Component} from 'ngx-captcha';
import {environment} from '../../environments/environment';
import {InternalCommunicationService} from './../internal-communication.service';
import {Subscription} from 'rxjs';
import {KenticoService} from '../kentico.service';
import {ActivatedRoute} from '@angular/router';
import { PitValidators } from '../validators/pit-validators';
import {Passenger, PassengersList, Payment} from '../../types/formPassengers';
import {ARGENTINA, BRASIL, CHILE, COLOMBIA, PERU, URUGUAY, PARAGUAY, CREATED, EMAIL_SENT, NO, USA, OTROS, ECUADOR} from '../../constants/forms';
import * as moment from 'moment';
import {PitUtils} from '../validators/pit-utils';

const Estados_permitidos = [
  'CREATED', 'EMAIL_SENT'
];

@Component({
  selector: 'app-refund-list',
  templateUrl: './refund-list.component.html',
  styleUrls: ['./refund-list.component.scss']
})
export class RefundListComponent implements OnChanges, OnDestroy {
  additionals: number = 0;
  cmsListSection;
  countries = ['Chile', 'Argentina', 'Colombia', 'Peru', 'Uruguay', 'Paraguay', 'USA', 'Otros'];
  countrySelected = 'NoCountry';
  countryOrigin = '';
  cultureKentico: string;
  currencyCode: string = '';
  checkboxesCount = 0;
  changeConditions = false;
  changeConditionsText = `error_msg_changed_conditions`;
  textChileCompraRedirect = `msj_redirect_chilecompra`;
  urlChileCompraRedirect = `url_redirect_chilecompra`;
  devolucionesCheckbox: UntypedFormGroup;
  devolucionCompleta = false;
  errors = false;
  getPayments = 0;
  historics = false;
  submitted = false;
  lastForm: UntypedFormGroup;
  loaded = false;
  modal = false;
  modalEmail: string;
  overlay: boolean[] = [];
  reservas;
  response;
  refundType: string;
  payments: void;
  @Input() redirectedPnr: string;
  @Input() redirectToken: string;
  @Input() redirectedEmail: string;
  subscription: Subscription;
  validCheckbox = false;
  devolucionForm: UntypedFormGroup;
  texto = '';
  total: number = 0;
  showAccountForm: boolean;
  @Output() finishChanged: EventEmitter<boolean> = new EventEmitter();
  @Output() loadedEmitter: EventEmitter<boolean> = new EventEmitter();
  @Input() search: { pnr: string, Recaptcha: string };
  @Input() buscando: number;
  @ViewChild('lista', {read: ElementRef}) lista: ElementRef;
  @ViewChild('formdiv', {read: ElementRef}) formDiv: ElementRef;
  displayProcess: boolean = false;
  public readonly siteKey = environment.captchaPublicKey;
  public theme = 'light';
  public size = 'normal';
  public lang = 'es';
  public type = 'image';
  @ViewChild('captchaElem') captchaElem: ReCaptcha2Component;
  captchaSuccess: boolean = false;
  captchaResponse?: string;
  captchaIsExpired = false;
  captchaIsLoaded = false;
  politicies: boolean = false;
  errores_form: boolean = false;
  lastPayment: Payment ;
  lastDate: Date;
  flagAllStatus: boolean = false;
  lastSegmentRequested: boolean;
  combinedArray: [];
  combinedArreglo: [];
  cbc: UntypedFormGroup;
  legsArray: [];
  //Se añaden variables searchEmail y searchPnr para pasarlas a traves de los componentes y poder mostrarlas en el redirect de error noShow
  @Input() searchEmail: string;
  @Input() searchPnr: string;
  baseUrl = environment.url;

  isAnArray(item): boolean {
    return Array.isArray(item);
  }
  generateURL(item): string {
    return this.cmsListSection[item][0]['url'];
  }
  constructor(public reservaService: RefundService, private formBuilder: UntypedFormBuilder, private lastFormBuilder: UntypedFormBuilder,
              private formBuilderCheck: UntypedFormBuilder, private internalMsg: InternalCommunicationService, private cd: ChangeDetectorRef,
              private kenticoService: KenticoService, private activatedRoute: ActivatedRoute) {
  }

  OnInit() {
    this.showAccountForm = this.reservaService.showAccountForm;
  }

  getMomentsDiff(arrivalTime, departureTime) {
    const duration = moment.duration(moment(departureTime).diff(moment(arrivalTime)));
    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();
    return ((hours ? `${hours} ${this.cmsListSection.singular_hour_text || 'hora'}${hours > 1 ? 's' : ''} ` : '') +
      (minutes ? minutes + ' ' + ((this.cmsListSection.singular_minute_text || 'minuto') + (minutes > 1 ? 's' : '')) : ''));
  }

  getStation(reserva, stationCode) {
    try {
      return `${reserva.legsStationsInfo.find(stationInfo => stationInfo.StationCode === stationCode).CityName} (${stationCode})`;  
    } catch (error) {
      console.error('Error al obtener la propiedad "CityName" en la función "getStation"');
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['search']) {
      this.getPayments = 0;
      this.changeConditions = false;
      this.devolucionCompleta = false;
      this.historics = false;
      this.loaded = false;
      this.errors = false;
      this.submitted = false;
      this.reservas = null;
      this.overlay = [];
      this.devolucionesCheckbox = this.formBuilderCheck.group({});
      this.lastForm = this.lastFormBuilder.group({});
      this.payments = null;
      this.lang = this.activatedRoute.snapshot.params.lang;
      this.cultureKentico = this.lang + '_' + this.activatedRoute.snapshot.params.country;
      this.currencyCode = '';
      this.lastPayment = undefined;
      Promise.all([
        this.getReservas(),
        this.getListFormCMSSections()
      ]).then(() => {
        this.dynamicAmounts();
        try {
          this.lista.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start'});
        } catch (e) {
          console.error(e);
        }
        // this.loaded = true;
        // this.loadedEmitter.emit(this.loaded);
      });
    }
  }

  changePoliticies() {
    if (!this.politicies) {
      this.politicies = true;
    } else {
      this.politicies = false;
    }
    return;
  }

  checkDisablePaxes() {
    let totalPaxes = 0;
    let disabledPaxes = 0;
    let totalPaxByJourney = 0;
    let disabledPaxesByJourney = 0;
    if (this.reservas && this.reservas.length > 0 && this.historics === false) {
      this.reservas.map((reserva_map, index) => {
        reserva_map.Pax.map((pasajero: Passenger, index2) => {
          const estado = pasajero.Status;
          totalPaxes++;
          totalPaxByJourney++;
          if (pasajero.RefundAmount === 0 || (estado && Estados_permitidos.indexOf(estado) === -1)) {
            this.devolucionesCheckbox.controls[index].get(index2.toString()).get('check').disable();
            disabledPaxes++;
            disabledPaxesByJourney++;
          }
          if (this.checkboxesCount > 0) {
            this.checkboxesCount--;
          }
        });
        if (totalPaxByJourney === disabledPaxesByJourney) {
          this.overlay[index] = true;
        }
        totalPaxByJourney = 0;
        disabledPaxesByJourney = 0;
      });
      if (totalPaxes === disabledPaxes) {
        this.devolucionCompleta = true;
      }
    }

  }

  get L() {
    return this.lastForm.controls;
  }
  getReservas(): Promise<any> {
    this.politicies = false;
    this.errores_form = false;
    return new Promise<void>((resolve, reject) => {
      this.reservaService.getReservas(this.search)
        .then(resp => {
          if (resp.status === 204 || resp.status >= 400) {
            this.reservas = [];
            this.errors = true;
            const modalText = resp.reserva.msg; // contenido del modal
            this.texto = modalText;
            this.modal = true;
            this.loaded = true;
            this.subscription = this.internalMsg.getMessage().subscribe(msg => {
              this.modal = false;
            });
            resolve();
          } else {
            if (this.modal) {
              this.modal = false;
            }
            this.reservas = resp.reserva;
            this.response = resp.reserva;
            if(this.reservas[0].urlIsChileCompra) this.modal = true;
            const unprocessedLegs = resp.reserva.map(reserva => reserva.legs);
            this.legsArray = unprocessedLegs.map((segmentLegs, outerIndex) =>
              segmentLegs.map((leg, index) => {
                return {
                  ...leg,
                  ...(index !== (unprocessedLegs[outerIndex].length - 1)) && { tiempoDeEspera: this.getMomentsDiff(unprocessedLegs[outerIndex][index].designator.arrival, unprocessedLegs[outerIndex][index + 1].designator.departure) }
                };
              })
            );
            const checkboxControls = this.formBuilder.group({});
            this.cbc = checkboxControls;
            this.reservas.map((segmentos, index) => {
              if (segmentos.Status && segmentos.Status === 'HISTORICS') {
                this.historics = true;
                resolve();
              }
              this.overlay.push(false);
              segmentos.Pax.map((pax, index2) => {
                // tslint:disable-next-line:max-line-length
                if (this.reservas[index].Pax[index2] && this.reservas[index].Pax[index2].Payment && this.reservas[index].Pax[index2].Payment.Country) {
                  this.countrySelected = this.reservas[index].Pax[index2].Payment.Country;
                  this.countryOrigin = this.countrySelected;
                } else {
                  this.countrySelected = 'NoCountry';
                  this.countryOrigin = this.countrySelected;
                }
                if (this.reservas[index].Pax[index2].Payment) {
                  this.payments = this.reservas[index].Pax[index2].Payment;
                }
                if (this.currencyCode === ''  && pax.CurrencyCode ) {
                  this.currencyCode = pax.CurrencyCode;
                }
              });
            });
            this.lastForm = this.lastFormBuilder.group({
              Accept: ['', Validators.required],
              Countries: [this.countryOrigin],
              recaptcha: ['', Validators.required]
            });
            if (this.historics === false) {
              this.reservas.map((reserva_map, index) => {
                const controls: UntypedFormGroup[] = [];
                reserva_map.Pax.map((pasajero: Passenger, index2) => {
                  if (pasajero.ChangedConditions && pasajero.ChangedConditions === true && !this.changeConditions) {
                    this.changeConditions = true;
                  }
                  if (pasajero.Payment && pasajero.UpdatedDateTime ) {
                    if (!this.lastPayment) {
                      this.lastPayment = pasajero.Payment;
                      this.lastDate = pasajero.UpdatedDateTime;
                    } else if (this.lastDate < pasajero.UpdatedDateTime) {
                      this.lastPayment = pasajero.Payment;
                      this.lastDate = pasajero.UpdatedDateTime;
                    }
                  }
                  if (pasajero.IndRefundType !== 'NO') {
                    this.refundType = pasajero.IndRefundType;
                  }
                  this.checkboxesCount++;
                  controls.push(new UntypedFormGroup({
                    check: new UntypedFormControl({
                      value: (!this.validatePassenger(pasajero) && typeof pasajero.Payment !== 'undefined') || (this.reservaService.voucherRefund && pasajero.RefundAmount > 0 && pasajero.IndRefundType !== 'NO'),
                      disabled: this.validatePassenger(pasajero) || this.reservaService.voucherRefund
                    }, [Validators.required])
                    // , disabled: pasajero.RefundAmount === 0 })
                  }));
                });
                checkboxControls.addControl(index.toString(), new UntypedFormArray(controls));
              });
            }
            if (this.lastPayment) {
              this.countrySelected = this.lastPayment.Country;
            } else {
              this.countrySelected = 'NoCountry';
            }
            this.devolucionesCheckbox = checkboxControls;
            this.checkDisablePaxes();
            this.handleCheckbox(true);
            this.loaded = true;
            const datetime = new Date(this.reservas[0].Pax[0].STD);
            if (this.reservas[1]) {
              const datetime2 = new Date(this.reservas[1].Pax[0].STD);
            }
            this.loadedEmitter.emit(this.loaded);
            this.subscription = this.internalMsg.getMessage().subscribe(msg => {
              this.changeConditions = false;
              resolve();
            });
            resolve();
          }
        });
    });
  }

  validatePassenger(passenger: Passenger): boolean {
    return (passenger.IndRefundType === NO || passenger.RefundAmount === 0
      || passenger.IndRefundExist === 'Y' || (passenger.Status !== null &&
        passenger.Status !== CREATED && passenger.Status !== EMAIL_SENT));
  }

  getDate(fecha: string): string {
    const datetime = new Date(fecha);
    const dia = datetime.getDate().toString().padStart(2, '0');
    const mes = (datetime.getMonth() + 1).toString().padStart(2, '0');
    const horaEnNum = datetime.getHours();
    const horaAMPM = horaEnNum === 0 ? 12 : horaEnNum > 12 ? horaEnNum - 12 : horaEnNum;
    const hora = horaAMPM.toString().padStart(2, '0');
    const ampm = horaEnNum === 0 ? 'am' : horaEnNum >= 12 && horaEnNum < 24 ? 'pm' : 'am';
    const minutos = datetime.getMinutes().toString().padStart(2, '0');
    return (dia + '/' + mes + '/' + datetime.getFullYear() + ' ' + hora + ':' + minutos + ' ' + ampm);
  }

  closeModalHandler(close: any) {
    this.modal = false;
  }

  handleCheckbox(e) {
    const aditionalAux = this.dynamicAmounts();
    if (this.canPay()) {
      this.validCheckbox = true;
      this.displayProcess = true;
      // aqui fue la linea
      // this.countrySelected = CHILE;
    } else {
      this.validCheckbox = false;
      this.displayProcess = false;
      this.captchaSuccess = false;
    }
    this.verifyAllStatus();
    if (this.flagAllStatus && aditionalAux > 0) {
      this.additionals = aditionalAux;
      return;
    } else {
      this.additionals = 0 ;
    }
  }

  canPay(): boolean {
    const devolucionesControl = this.devolucionesCheckbox.controls;
    for (const formArray in devolucionesControl) {
      if (devolucionesControl.hasOwnProperty(formArray)) {
        for (const value in this.response[formArray].Pax) {
          if (devolucionesControl[formArray].get(value)) {
            const values = devolucionesControl[formArray].get(value).value;
            const disabled = devolucionesControl[formArray].get(value).status;
            if ((values.check && disabled !==  'DISABLED') || values.check && this.reservaService.voucherRefund ) {
              return true ;
            }
          }
        }
      }
    }
    return false ;
  }
  verifyAllStatus() {
    const devolucionesControl = this.devolucionesCheckbox.controls;
    for (const formArray in devolucionesControl) {
      if (devolucionesControl.hasOwnProperty(formArray)) {
        for (const value in this.response[formArray].Pax) {
          if (devolucionesControl[formArray].get(value)) {
            const values = devolucionesControl[formArray].get(value).value;
            const disabled = devolucionesControl[formArray].get(value).status;
            if (!values.check) {
              this.flagAllStatus = false;
              return ;
            }
          }
        }
      }
    }
    this.flagAllStatus = true;
    return ;
  }
  dynamicAmounts(): number {
    let mustAdditional = true;
    let unChecked = 0;
    let total: number = 0;
    let additional: number = 0;
    this.reservas.map((segmentos, index) => {
      segmentos.Pax.map((pax: Passenger, index2) => {
        if (pax.IndRefundType === 'PARCIAL' || pax.IndRefundType === 'NO') {
          mustAdditional = false;
        }
        if (pax.IndRefundType !== 'NO') {
          const checkbox = this.devolucionesCheckbox.controls[index].get(index2.toString());
          if (checkbox.value.check === true && (checkbox.status !== 'DISABLED' || this.reservaService.voucherRefund)) {
            total += Number(pax.RefundAmount);
          } else if (checkbox.status !== 'DISABLED') {
            unChecked++;
          }
        }
      });
      if (unChecked > 0) {
        mustAdditional = false;
      }
       additional +=  segmentos.TotalAmountWithoutSegment;
    });
    // @ts-ignore
    if (!mustAdditional) {
      additional = 0 ;
    }
    this.total = total;
    return Math.abs(additional);
  }
  changeCountry(e) {
    this.countrySelected = e;
    this.getPayments = 0;
  }
  changeError() {
    this.errores_form = false;
  }
  handleCountry(e) {
    this.countrySelected = e.target.value;
    this.getPayments = 0;
  }

  onSubmit() {
   this.submitted = true;
   if (!this.reservaService.showAccountForm) {
    this.processRefound({
      AccountType: '-',
      AccountBank: '-',
      AccountNumber: '-',
      AccountEmail: '-',
      AccountName: '-',
      AccountRUT : '-',
      AccountTypeDocumentPE: '-',
      AccountRUC : '-',
      AccountDNI: '-',
      AccountTypeCurrency: '-',
      AccountCCI : '-',
      AccountRUTUY : '-',
      AccountCIPY: '-',
      AccountCBU : '-',
      AccountSucursal : '-',
      AccountCUIT : '-',
      AccountIDDocumentType : '-',
      AccountIDDocumentNumber : '-',
      AccountPhone : '-',
      AccountCPF : '-',
      AccountIBAN: '-',
      AccountAgency : '-',
      AccountCountry : '-',
      AccountTax : '-',
      AccountSwiftCode: '-',
      AccountBankBenef : '-',
      AccountBankInter : '-',
      AccountCity : '-',
      AccountAddress : '-',
      Country : '-',
    });
    this.getPayments++;
   } else if (this.devolucionForm) {
      const paymentAux: Payment = this.validateUndefined(this.devolucionForm.value[0]); // validate undefined cambia undefined, null o '' por '-'
      if ((!this.validCheckbox || this.lastForm.invalid) && !this.reservaService.voucherRefund) {
        return;
      } else if (this.validatePayment(paymentAux)) {
        this.processRefound(this.devolucionForm.value[0]);
        this.getPayments++;
      } else {
        try {
          this.formDiv.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start'});
        } catch (e) {
          console.error(e);
        }
      }
    } else {
      this.errores_form = true;
    }
  }

  validateUndefined(payment: Payment): Payment {
    for (const prop in payment) {
      if (payment[prop] === undefined || payment[prop] === null || payment[prop] === '') {
        payment[prop] = '-';
      }
    }
    return payment;
  }

  validateIBAN(AccountIBAN:string):boolean {
    if(AccountIBAN.length > 1 ){
        let checkIbanFormat = PitValidators.isValidIBAN(AccountIBAN);
        return checkIbanFormat
    }
    return true
  };

  validatePayment(payment: Payment): boolean {
    
    if(payment.keepAccount == true) return true;  // se conserva la cuenta como estaba
    
    const commonValidators = (paymnt: Payment) => {
      return ((paymnt.AccountNumber.length > 2 &&
        paymnt.AccountName.length > 3 &&
        RegExp('[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}').test(paymnt.AccountEmail) &&
        paymnt.AccountEmail.length > 3 &&
        RegExp('^[0-9]+$').test(paymnt.AccountNumber)) ? true : false);
    };
    const nonOtherValidators = (paymnt: Payment) => {
      return ((paymnt.AccountBank.length > 3 && paymnt.AccountType.length > 2) ? true : false);
    };
    const peruDocIdTypeValidator = (paymnt: Payment) => { 

      if (!payment.AccountDNI && payment.AccountRUC) {
        return (payment.AccountRUC.length == 11  && PitValidators.isValidRuc(payment.AccountRUC) && payment.AccountCCI.length == 20 && payment.AccountCCI.match(/^[0-9]+$/) !== null )
      }else if(!payment.AccountRUC && payment.AccountDNI ){
        return (payment.AccountDNI.length == 8 && payment.AccountCCI.length == 20 && payment.AccountCCI.match(/^[0-9]+$/) !== null)
      }
      return false
    }

    if (this.countrySelected === CHILE &&
      payment.AccountRUT.length > 2 && PitValidators.rutDvStr(payment.AccountRUT) &&
      commonValidators(payment) && nonOtherValidators(payment)) {
      return true;
    } else if (this.countrySelected === ARGENTINA 
        && payment.AccountSucursal.length > 1 
        && payment.AccountCUIT.length > 3 
        && payment.AccountCBU.length == 22 
        && PitUtils.isOnlyNumber(payment.AccountCBU) &&
      commonValidators(payment) && nonOtherValidators(payment)) {
      return true;
    } else if (this.countrySelected === OTROS &&
      payment.AccountBankInter.length > 3 &&
      payment.AccountBankBenef.length > 3 &&
      payment.AccountCountry.length > 2 &&
      payment.AccountTax.length > 3 &&
      payment.AccountAddress.length > 3 &&
      payment.AccountCity.length > 3 &&
      commonValidators(payment)) {
      return true;
    }else if (this.countrySelected === USA &&
      payment.AccountBankInter.length > 3 &&
      payment.AccountBankBenef.length > 3 &&
      payment.AccountAddress.length > 3 &&
      payment.AccountCity.length > 3 &&
      commonValidators(payment)) {
      return true;
    }
     else if (this.countrySelected === BRASIL && this.currencyCode != 'USD' &&
      payment.AccountCPF.length == 11 && PitUtils.isOnlyNumber(payment.AccountCPF) && payment.AccountAgency.length > 1 && this.validateIBAN(payment.AccountIBAN) &&
      commonValidators(payment) && nonOtherValidators(payment)) {
      return true;
    } else if (this.countrySelected === BRASIL && this.currencyCode === 'USD' &&
    payment.AccountCPF.length == 11 && PitUtils.isOnlyNumber(payment.AccountCPF) && payment.AccountAgency.length > 1 && this.validateIBAN(payment.AccountIBAN) &&
    commonValidators(payment) && nonOtherValidators(payment)){
      return true;
    }else if (this.countrySelected === COLOMBIA &&
      payment.AccountPhone.length > 2 && payment.AccountIDDocumentType.length > 2 && payment.AccountIDDocumentNumber.length > 2 &&
      commonValidators(payment) && nonOtherValidators(payment)) {
      return true;
    } else if (this.currencyCode != 'USD' && this.countrySelected === PERU && peruDocIdTypeValidator(payment) && payment.AccountCCI.length == 20 &&
      commonValidators(payment) && nonOtherValidators(payment) && payment.AccountTypeCurrency.length > 1) {   
      return true;
    } else if (this.currencyCode === 'USD' && this.countrySelected === PERU && peruDocIdTypeValidator(payment) && payment.AccountCCI.length == 20 &&
      commonValidators(payment) && nonOtherValidators(payment) && payment.AccountTypeCurrency.length > 1) {      
      return true;
    } else if (this.countrySelected === URUGUAY &&
      payment.AccountRUTUY.length > 6 && payment.AccountAddress.length > 3 && payment.AccountSucursal.length > 1 && PitValidators.isValidRutUy(payment.AccountRUTUY) &&
      commonValidators(payment) && nonOtherValidators(payment)) {      
      return true;
    } else if (this.countrySelected === PARAGUAY &&
      payment.AccountCIPY.length > 6  && commonValidators(payment) && nonOtherValidators(payment)) {      
      return true;
    } else if (this.countrySelected === ECUADOR && payment.AccountIDDocumentType.length > 1 && payment.AccountIDDocumentNumber.length > 2
      && commonValidators(payment) && nonOtherValidators(payment) ) {
      return true;
    } else if (!this.reservaService.showAccountForm) {
      return true;
    } else {
      this.errores_form = true;
      return false;
    }
  }

  processRefound(payment: Payment) {
    const responseCopy = JSON.parse(JSON.stringify(this.response));
    this.lastSegmentRequested = (Object.values(this.cbc.value) as { check: boolean }[][]).flat().every(passengerCheckbox => passengerCheckbox.check);

    setTimeout(() => this.loaded = false, 0);
    this.reservas.map((reserva_map, index) => {
      responseCopy[index].Recaptcha = this.lastForm.controls.recaptcha.value;
      responseCopy[index].Culture = this.cultureKentico;
      responseCopy[index].countrySelected = this.countrySelected;
      responseCopy[index].lastSegmentRequested = this.lastSegmentRequested;
      responseCopy[index].allSegmentsRequested = !this.reservaService.previousRefundRequested && this.lastSegmentRequested;
      reserva_map.Pax.map((pasajero, index2) => {
        const valor = this.devolucionesCheckbox.controls[index].get(index2.toString()).get('check').value;
        const estado = responseCopy[index].Pax[index2].Status;
        if (responseCopy[index].Pax[index2].Payment) {
          delete responseCopy[index].Pax[index2].Payment;
        }
        if (valor && ((estado && Estados_permitidos.indexOf(estado) !== -1) || !estado)) {
          // tslint:disable-next-line:no-shadowed-variable
          payment['Country'] = this.countrySelected;
          // delete Payment.recaptcha;
          responseCopy[index].Pax[index2].Payment = payment; // Aquí se agrega lo de la forma de la cuenta a lo de los pasajeros y los tramos seleccionados
        }
      });
    });
    this.reservaService.createRefundCase(responseCopy)
      .then(resp => {
        this.reservas = [];
        this.errors = true;
        this.loaded = true;
        let modalText = '';
        if (resp.status >= 400) {
          modalText = resp.body.msg ? resp.body.msg : environment.modalTexts[500];
        } else {
          modalText = resp.body.msg ? resp.body.msg : 'Devolución creada de forma exitosa';
          this.modalEmail = resp.body.msgEmail ? resp.body.msgEmail : '';
        }
        this.texto = modalText;
        this.modal = true;
        this.errores_form = false;
        this.subscription = this.internalMsg.getMessage().subscribe(msg => {
          this.modal = false;
        });
        if (this.reservaService.showAccountForm) {this.devolucionForm.reset(); } // Unica duda
        return;
      });
    this.politicies = false;
    this.currencyCode = '';
    this.lastPayment = undefined;
    this.reset();
  }

  submitHandler(e: any) {
    this.submitted = true;
    const devolucionesControl = this.devolucionesCheckbox.controls;
    this.devolucionForm = e;
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  // reCaptcha functions
  handleReset(): void {
    this.captchaSuccess = false;
    this.captchaResponse = undefined;
    this.captchaIsExpired = false;
  }

  handleSuccess(captchaResponse: string): void {
    this.captchaSuccess = true;
    this.captchaResponse = captchaResponse;
    this.captchaIsExpired = false;
  }

  handleLoad(): void {
    this.captchaIsLoaded = true;
    this.captchaIsExpired = false;
  }

  handleExpire(): void {
    this.captchaSuccess = false;
    this.captchaIsExpired = true;
  }

  reset(): void {
    // this.captchaElem.resetCaptcha();
    this.captchaSuccess = false;
    this.captchaElem.reCaptchaApi.reset();
  }

  getListFormCMSSections(): Promise<any> {

    return new Promise<void>((resolve, reject) => {
      this.kenticoService.getItems('devoluciones', this.cultureKentico)
        .then(resp => {
          const text = resp.form_voucher_conditions_text;
          const urls = resp.form_voucher_conditions_text_links;
          const texto = resp.list_new_title_voucher;
          const bold = resp.list_new_title_voucher_bold;

          const textURLsMap = urls.split('||').map(item => item.split('|')).sort((a, b) => text.indexOf(a[0]) - text.indexOf(b[0]));
          const splittedText = textURLsMap.reduce((acc, curr, index) =>
            acc.concat(acc.splice(-1)[0].split(textURLsMap[index][0])), [text]);
          this.combinedArray = splittedText.reduce((acc, curr, index) =>
            acc.concat([splittedText[index]].concat([textURLsMap[index]])), []).filter(item => item);

          const splittedBold = bold.split('||').sort((a, b) => texto.indexOf(a) - texto.indexOf(b)).map(item => [item]);

          const splittedTexto = splittedBold.reduce((acc, curr, index) =>
            acc.concat(acc.splice(-1)[0].split(splittedBold[index])), [texto]);

          this.combinedArreglo = splittedTexto.reduce((acc, curr, index) =>
            acc.concat([splittedTexto[index]].concat([splittedBold[index]])), []).filter(item => item);

          this.cmsListSection = resp ? resp : {};
          resolve();
        });
    });

  }
  selectedCheckboxes(segmentIndex: Number) {
    return (this.cbc['value'][segmentIndex.toString()] || []).flatMap(checkbox => checkbox.check);
  }
  everyPaxIsSelected(segmentIndex: Number) {
    return this.selectedCheckboxes(segmentIndex).every(checkboxValue => checkboxValue === true);
  }
  somePaxIsSelected(segmentIndex: Number) {
    return this.selectedCheckboxes(segmentIndex).some(checkboxValue => checkboxValue === true);
  }
  setAllEnabledPassengersTo(value: Boolean, segmentIndex: Number) {
    this.cbc.get(segmentIndex.toString())['controls'].filter(formGroup => formGroup.controls.check.enabled).forEach(formGroup => formGroup.controls.check.setValue(value));
  }
  toggleCheckboxes(segmentIndex: Number) {
    if (!this.reservaService.voucherRefund) {
      if (!this.everyPaxIsSelected(segmentIndex)) {
        this.setAllEnabledPassengersTo(true, segmentIndex);
      } else if (this.everyPaxIsSelected(segmentIndex)) {
        this.setAllEnabledPassengersTo(false, segmentIndex);
      }
      this.handleCheckbox(true);
    }
  }
}
