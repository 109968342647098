import { Injectable } from '@angular/core';
import { Reserva } from './reserva';
import { reservas } from './mock-reserva';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from '../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class RefundService {
  previousRefundRequested: boolean;

  constructor(private http: HttpClient) { }

  showAccountForm: boolean;

  voucherRefund: boolean;

  hasPercentGC: any;

  isCaravelo: boolean;

  urlIsChileCompra: any;

  isChileCompra: boolean;

  headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json' });
  showAccountFormM(reserva): boolean {
    return reserva.body.msg ? false : reserva.body.flatMap(segment => segment.showAccountForm).some(showAccountForm => showAccountForm);
  }

  voucherRefundM(reserva): boolean {
    return reserva.body.msg ? true : reserva.body.flatMap(segment => segment.voucherRefund).some(voucherRefund => voucherRefund);
  }

  hasPercentGCM(reserva): any {
    
    return Array.isArray(reserva.body) ? reserva.body.flatMap(segment => segment.hasPercentGC).find(hasPercentGC => hasPercentGC) : false
  }

  isCaraveloM(reserva): boolean {
    return reserva.body.msg ? false : reserva.body.flatMap(segment => segment.isCaravelo).some(isCaravelo => isCaravelo);
  }

  isChileCompraM(reserva): boolean {
    return reserva.body.msg ? false : reserva.body.flatMap(segment => segment.isChileCompra).some(isChileCompra => isChileCompra);
  }

  hasUrlIsChileCompraM(reserva): any {
    return Array.isArray(reserva.body) ? reserva.body.flatMap(segment => segment.urlIsChileCompra).find(urlIsChileCompra => urlIsChileCompra) : false
  }

  previousRefundRequestedM(reserva): boolean {
    return reserva.body.msg ? false : reserva.body.flatMap(segment => segment.Pax).some(Pax => !['CREATED', 'EMAIL_SENT', 'HISTORICS'].includes(Pax.Status));
  }

  getReservas(search: { pnr: string, Recaptcha?: string, redirectToken?: string }): Promise<any> {
    return new Promise<any>((resolve, reject) => {

    /* resolve({ reserva: reservas,
    status: 200 }); */
      this.request(environment.getBookingInfo, JSON.stringify(search))
        .then((response: HttpResponse<any>) => {
          this.isChileCompra = this.isChileCompraM(response);
          this.urlIsChileCompra = this.hasUrlIsChileCompraM(response);
          if (this.urlIsChileCompra ) {
            //window.location.href = this.urlIsChileCompra;
            //resolve(null);  
          }
          console.log('response', response);
          console.log('refundService this.showAccountFormM(response) :', this.showAccountFormM(response));
          console.log('refundService this.voucherRefundM(reserva) :', this.voucherRefundM(response));
          this.showAccountForm = this.showAccountFormM(response);
          this.voucherRefund = this.voucherRefundM(response);
          this.hasPercentGC = this.hasPercentGCM(response); 
          this.isCaravelo = this.isCaraveloM(response);
          this.previousRefundRequested = this.previousRefundRequestedM(response);
          // TODO: agregar más status codes
          if (response.status === 204) {
            resolve({ reserva: [], status: 204 });
          } else if (response.status === 200 && response.body.msg) {
            resolve({ reserva: response.body, status: 204 });
          } else if (response.status === 200 && typeof response.body.msg === 'undefined') {
            resolve({ reserva: response.body, status: 200 });
          } else if (response.status === 400) {
            resolve({ reserva: response.body, status: 400 });
          } else if (response.status === 504) {
            resolve({ response: environment.modalTexts[504], status: response.status });
          } else {
            resolve({ reserva: response.body, status: 999 });
          }
        },
          (err) => {
            console.log(err);
            resolve({ reserva: { msg: environment.modalTexts[504]}, status: 504 });
          });
    });
  }
  confirmEmailValidate(request: { pnr: string, ConfirmToken: string }): Promise<{
    body: { msg: string, msg_stc: string }, status: number
  }> {
    return new Promise<any>((resolve, reject) => {
      this.request(environment.confirmEmailValidate, JSON.stringify({ request }))
        .then((response: HttpResponse<any>) => {
          // tODO: agregar más status codes
          if (response.status === 204) {
            resolve({
              body: `Estimado usuario, no se ha encontrado una reserva asociada con este código.
             Por favor contactárse con nuestro contact center.`, status: 204
            });
          } else if (response.status === 201) {
            resolve({ body: `error`, status: 201 });
          } else if (response.status === 409) {
            resolve({ body: response.body, status: response.status });
          } else if (response.status === 504) {
            resolve({ body: environment.modalTexts[504], status: response.status });
          } else {
            resolve({ body: response.body, status: response.status });
          }
        },
          (err) => {
            console.log(err);
            resolve({ body: { msg: environment.modalTexts[504]}, status: 504 });
          });
    });
  }
  createRefundCase(reserva: Reserva[]): Promise<{
    body: { msg: string, msgEmail: string }, status: number
  }> {
    return new Promise<any>((resolve, reject) => {
      this.request(environment.createRefundCase, JSON.stringify({ reserva }))
        .then((response: HttpResponse<any>) => {
          // tODO: agregar más status codes

          if (response.status === 204) {
            resolve([]);
          } else if (response.status === 201) {
            resolve({ body: response.body, status: response.status });
          } else if (response.status >= 400 && response.status <= 500) {
            resolve({ body: environment.modalTexts[response.status], status: response.status });
          } else if (response.status === 502) {
            resolve({ body: environment.modalTexts[response.status], status: response.status });
          }
        },
          (err) => {
            console.log(err);
            resolve({ body: { msg: environment.modalTexts[504] }, status: 504 });
          });
    });
  }
  // console.log('encrypt ', this.encrypt(pnr));
  // return this.http.post(this.urlGetBookingInfo, JSON.stringify({pnr: pnr}), {headers: this.headers, observe: 'response'}  );

  getAccountType(country: string): Promise<any> {
    country == 'Peru' ? country = 'Peru' : country;
    const param = {
      url: environment.getAccountType.url + environment.countryCodes[country],
      method: environment.getAccountType.method
    };
    return new Promise<any>((resolve, reject) => {
      this.request(param, {})
        .then((response: HttpResponse<any>) => {
          resolve(response.body);
        },
          (err) => reject(err));
    });
    // return this.http.get(this.urlListBanks, {headers: this.headers});
  }
  listBanks(country: string): Promise<any> {
    country == 'Peru' ? country = 'Peru' : country;
    const param = {
      url: environment.listBanks.url + environment.countryCodes[country],
      method: environment.listBanks.method
    };
    return new Promise<any>((resolve, reject) => {
      this.request(param, {})
        .then((response: HttpResponse<any>) => {
          resolve(response.body);
        },
          (err) => reject(err));
    });
    // return this.http.get(this.urlListBanks, {headers: this.headers});
  }
  listTypesCol(country: string): Promise<any> {
    const param = {
      url: environment.getTypesCol.url + environment.countryCodes[country],
      method: environment.listBanks.method
    };
    return new Promise<any>((resolve, reject) => {
      this.request(param, {})
        .then((response: HttpResponse<any>) => {
            resolve(response.body);
          },
          (err) => reject(err));
    });
  }
  private request(servicio: { url: string, method: string }, body?): Promise<HttpResponse<object>> {
    if (!body) {
      body = {};
    }

    return new Promise((resolve, reject) => {
      this.http.request(servicio.method, servicio.url,
        { body: body, headers: this.headers, observe: 'response', responseType: 'json' })
        .subscribe(
          data => {
            resolve(data);
            // console.log('Respuesta de api ', data);
          },
          err => {
            console.log('Error ', err);
            reject(err);
          }
        );
    });
  }
}
