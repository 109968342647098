<head>
    <title>Devoluciones | JetSmart</title>
    <meta charset="utf-8">
    <meta name="description" content=" ">
    <meta http-equiv="x-ua-compatible" content="ie=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1">
</head>
<header #head_tag class="main-header fixed-top scroll-friendly" (window:resize)="onResize($event)">
    <div class="container">
        <nav #navig_tag class="navbar navbar-expand-lg align-items-center">
            <a class="navbar-brand" href={{cmsMainSection.link_main_page}}> <img #imagen src={{cmsMainSection.logo_colored[0].url}}
                    (load)="loadedImg(imagen)" alt={{cmsMainSection.logo_colored_alt}} title={{cmsMainSection.logo_colored_title}} class="logo-jetsmart img-fluid" />
            </a>
            <div class="d-xl-none d-lg-none d-flex">
                <ul class="navbar-nav d-flex mobile-navbar-countries nav-justified justify-content-center align-items-center">
                    <li class="nav-item nav-countries dropdown d-flex h-100 align-middle justify-content-center align-items-center">
                        <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true"
                            aria-expanded="false"><span class="flag rounded-pin border-white-2"><img src={{cmsMainSection.flagsData[cultureSelected].img_url}}
                                    class="img-fluid rounded-pin" /></span><span>{{cmsMainSection.flagsData[cultureSelected].country}}</span></a>
                        <div class="dropdown-menu">
                          <div *ngFor="let flag of filteredDataFlags">
                            <a class="dropdown-item" href="{{url}}/{{flag.name}}/{{flag.lang}}/devoluciones"><span class="flag rounded-pin border-white-2"><img
                              src={{flag.img_url}} class="img-fluid rounded-pin" /></span>{{flag.country}}</a>
                          </div>
                        </div>
                    </li>
                </ul>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
                    aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation" (click)="onClickMenu()" >
                    <span class="navbar-toggler-icon"><span class="sr-only">Toggle navigation</span><span class="icon-bar"></span><span
                            class="icon-bar"></span><span class="icon-bar"></span><small>{{cmsMainSection.title_menu}}</small></span>
                </button>
            </div>
            <div class="col-xl-5 col-lg-4 d-xl-inline-block d-lg-inline-block d-none">&nbsp;</div>
            <div #dropdown_menu class="collapse navbar-collapse" id="navbarNav">
                <ul class="navbar-nav w-100 nav-justified justify-content-center align-items-center">
                    <li class="nav-item dropdown d-flex h-100 align-middle justify-content-center align-items-center">
                        <a class="nav-link" href="{{cmsMainSection.link_register}}"><span class="hover-border-bottom-center">{{cmsMainSection.text_link_register}}</span></a>
                    </li>
                    <li class="nav-item d-flex h-100 align-middle justify-content-center align-items-center">
                        <a class="nav-link" href="{{cmsMainSection.link_login}}"><span class="hover-border-bottom-center">{{cmsMainSection.text_login}}</span></a>
                    </li>
                    <li class="nav-item nav-countries dropdown d-xl-flex d-lg-flex d-none h-100 align-middle justify-content-center align-items-center">
                        <a class="nav-link dropdown-toggle" data-toggle="collapse" href="#" role="button" id="dropdown01"
                            data-target="#dd" aria-haspopup="true" aria-expanded="false">
                            <span class="flag rounded-pin border-white-2"><img src={{cultures[cultureSelected].img_url}} class="img-fluid rounded-pin" />
                            </span><span>{{cultures[cultureSelected].country}}</span></a>
                        <div #dropdown_tag class="dropdown-menu" aria-labelledby="dropdown01" id="dd">
                          <div *ngFor="let flag of filteredDataFlags">
                            <a class="dropdown-item" href="{{url}}/{{flag.name}}/{{flag.lang}}/devoluciones"><span class="flag rounded-pin border-white-2"><img
                              src={{flag.img_url}} class="img-fluid rounded-pin" /></span>{{flag.country}}</a>
                          </div>
                        </div>
                    </li>
                </ul>
            </div>
        </nav>
    </div>
</header>
