<div *ngIf="loaded===false">
  <app-loading [doScroll]=false class="text-center"></app-loading>
</div>
<div *ngIf="loaded ===true ">
  <h4 class="mt-1 d-inline color-blue-js font-lato-black"><img class="icon-form"
      src="https://{{baseUrl}}/assets/card.png" alt="">
    {{cmsListSection.form_title}}</h4>

  <div class="bank-account">
    <div class="row box-bank-account">
      <div class="col-md-10 col-sm-12 col-12">
        <form [formGroup]="devolucionForm">
          <div class="row" formGroupName="0">
            <div class="col-md-6 col-sm-6 col-12">
              <div class="form-group">
                <select [disabled]="keepAccount" *ngIf="!(lastPayment)" class="form-control input-card "
                  [ngClass]="{ 'is-invalid': errores_form && countrySelected.length >12 }"
                  (change)="handleCountry($event.target.value)" style="color: #0A355E;">

                  <option value="" disabled selected>{{ cmsListSection.country_placeholder }}</option>
                  <option [value]="pais" [disabled]="pais === cmsListSection.country_placeholder"
                    [selected]="pais === countrySelected" *ngFor="let pais of countries">
                    <div *ngIf="pais == 'Peru' "> Per&uacute; </div>
                    <div *ngIf="pais != 'Peru' "> {{pais}} </div>
                  </option>

                </select>

                <select [disabled]="keepAccount" *ngIf="lastPayment" class="form-control input-card "
                  [ngClass]="{ 'is-invalid': errores_form && countrySelected.length >12 }"
                  (change)="handleCountry($event.target.value)" style="color: #0A355E;">


                  <option [value]="pais" [selected]="pais === countrySelected" *ngFor="let pais of countries">{{pais}}
                  </option>
                </select>

                <div class="invalid-feedback" style="color: red; margin-bottom: 4px;">
                  {{ cmsListSection.required_validation_text }}
                </div>

              </div>

              <div *ngIf="countrySelected !== 'Otros' && countrySelected !== 'USA'" class="form-group">
                <select [disabled]="keepAccount" *ngIf="!(lastPayment && lastPayment.Country === countrySelected)"
                  class="form-control input-card "
                  [ngClass]="{ 'is-invalid': errores_form && accountSelected.length <2 }"
                  (change)="handleAccount($event.target.value)" style="color: #0A355E;">

                  <option value="" disabled selected>{{ cmsListSection.form_account_type_placeholder }}</option>
                  <option [value]="medio" [disabled]="medio === cmsListSection.form_account_type_placeholder"
                    *ngFor="let medio of mediosPago">{{medio}}</option>
                </select>

                <select [disabled]="keepAccount" *ngIf="lastPayment && lastPayment.Country === countrySelected "
                  class="form-control input-card "
                  [ngClass]="{ 'is-invalid': errores_form && accountSelected.length <2 }"
                  (change)="handleAccount($event.target.value)" style="color: #0A355E;">

                  <option value="" disabled>{{ cmsListSection.form_account_type_placeholder }}</option>
                  <option [value]="medio" [disabled]="medio === cmsListSection.form_account_type_placeholder"
                    [selected]="medio.toUpperCase()===lastPayment.AccountType.toUpperCase()"
                    *ngFor="let medio of mediosPago">{{medio}}</option>
                </select>

                <div class="invalid-feedback" style="color: red; margin-bottom: 4px;">
                  {{ cmsListSection.required_validation_text }}
                </div>
              </div>

              <div *ngIf="countrySelected === 'Otros'" class="form-group">
                <input [readonly]="keepAccount" placeholder="{{ cmsListSection.form_country_placeholder }}"
                  class="form-control input-card" formControlName="AccountCountry" name="AccountCountry "
                  (change)="editData($event)" style="color: #0A355E;"
                  [value]="lastPayment &&  lastPayment.Country ===countrySelected ? lastPayment.AccountCountry:''"
                  [ngClass]="{ 'is-invalid': errores_form && check.controls.AccountCountry.errors }" />
                <div *ngIf="errores_form && check.controls.AccountCountry.errors"
                  style="color: red; margin-bottom: 4px;" class="invalid-feedback">
                  <div
                    *ngIf="check.controls.AccountCountry.errors.required || check.controls.AccountCountry.errors.isEmpty">
                    {{ cmsListSection.required_validation_text }}</div>
                </div>
              </div>
              <div class="form-group">
                <input [readonly]="keepAccount" placeholder="{{ cmsListSection.form_account_number_placeholder }}"
                  type="text" style="color: #0A355E;" (change)="editData($event)" class="form-control input-card"
                  formControlName="AccountNumber" name="AccountNumber" formControlName="AccountNumber" name="Pnr"
                  [value]="lastPayment && lastPayment.Country ===countrySelected ? lastPayment.AccountNumber:''"
                  [ngClass]="{ 'is-invalid': errores_form && check.controls.AccountNumber.errors }" />
                <div *ngIf="errores_form && check.controls.AccountNumber.errors" class="invalid-feedback"
                  style="color: red;margin-bottom: 4px">
                  <div *ngIf="check.controls.AccountNumber.errors.required">{{ cmsListSection.required_validation_text
                    }}</div>
                  <div *ngIf="check.controls.AccountNumber.errors.pattern">{{ cmsListSection.format_validation_text }}
                  </div>
                </div>
              </div>

              <div class="form-group" *ngIf="countrySelected === 'Peru'">
                <input [readonly]="keepAccount" placeholder="Código cuenta interbancario - CCI"
                  class="form-control input-card" formControlName="AccountCCI" (change)="editData($event)"
                  name="AccountCCI" style="color: #0A355E;"
                  [value]="lastPayment && lastPayment.Country === countrySelected ? lastPayment.AccountCCI:'' "
                  [ngClass]="{ 'is-invalid': errores_form && check.controls.AccountCCI.errors }" />
                <div *ngIf="errores_form && check.controls.AccountCCI.errors" style="color: red; margin-bottom: 4px;"
                  class="invalid-feedback">
                  <div *ngIf="check.controls.AccountCCI.errors.required">{{ cmsListSection.required_validation_text }}
                  </div>
                  <div *ngIf="check.controls.AccountCCI.errors.invalidCCI">El código cuenta interbancario es inválido
                  </div>
                  <div
                    *ngIf="!check.controls.AccountCCI.errors.required && check.controls.AccountCCI.errors.invalidCCILength">
                    {{ msgValidationFields.CCI }}</div>
                </div>
              </div>

              <div class="form-group">
                <input [readonly]="keepAccount" placeholder="{{ cmsListSection.form_email_placeholder }}" type="email"
                  (change)="editData($event)" id="list-email" class="form-control input-card"
                  formControlName="AccountEmail" style="color: #0A355E;" name="AccountEmail"
                  [value]="lastPayment && lastPayment.Country ===countrySelected ? lastPayment.AccountEmail:'' "
                  [ngClass]="{ 'is-invalid': errores_form && check.controls.AccountEmail.errors }" />


                <div *ngIf="errores_form && check.controls.AccountEmail.errors" style="color: red; margin-bottom: 4px;"
                  class="invalid-feedback">
                  <div *ngIf="check.controls.AccountEmail.errors.required">{{ cmsListSection.required_validation_text }}
                  </div>
                  <div *ngIf="check.controls.AccountEmail.errors.email">{{ cmsListSection.email_validation_text }}</div>
                </div>
              </div>

              <div class="form-group" *ngIf="countrySelected === 'Brasil'">
                <input [readonly]="keepAccount" placeholder="IBAN (opcional)" class="form-control input-card"
                  formControlName="AccountIBAN" (change)="editData($event)" name="AccountIBAN" style="color: #0A355E;"
                  [value]="lastPayment && lastPayment.Country === countrySelected ? lastPayment.AccountIBAN:'' "
                  [ngClass]="{ 'is-invalid': check.controls.AccountIBAN.errors }" />
                <div *ngIf=" check.controls.AccountIBAN.errors" style="color: red; margin-bottom: 4px;"
                  class="invalid-feedback">

                  <div *ngIf="check.controls.AccountIBAN.errors.invalidIBAN">{{ cmsListSection.iban_validation_text }}
                  </div>
                </div>
              </div>

              <div class="form-group" *ngIf="countrySelected === 'Argentina' ">
                <input [readonly]="keepAccount" placeholder="{{ cmsListSection.form_locations_placeholder }}"
                  class="form-control input-card" formControlName="AccountSucursal" name="AccountSucursal"
                  (change)="editData($event)" style="color: #0A355E;"
                  [value]="lastPayment && lastPayment.Country ===countrySelected ? lastPayment.AccountSucursal:'' "
                  [ngClass]="{ 'is-invalid': errores_form && check.controls.AccountSucursal.errors }" />

                <div *ngIf="errores_form && check.controls.AccountSucursal.errors"
                  style="color: red; margin-bottom: 4px;" class="invalid-feedback">
                  <div
                    *ngIf="check.controls.AccountSucursal.errors.required || check.controls.AccountSucursal.errors.isEmpty">
                    {{ cmsListSection.required_validation_text }}</div>
                </div>
              </div>
              <div class="form-group" *ngIf="countrySelected === 'Colombia'">
                <input [readonly]="keepAccount" placeholder="{{ cmsListSection.form_account_phone_placeholder}}"
                  class="form-control input-card" formControlName="AccountPhone" name="AccountPhone"
                  (change)="editData($event)" style="color: #0A355E;"
                  [value]="lastPayment && lastPayment.Country ===countrySelected ? lastPayment.AccountPhone:'' "
                  [ngClass]="{ 'is-invalid': errores_form && check.controls.AccountPhone.errors }" />

                <div *ngIf="errores_form && check.controls.AccountPhone.errors" style="color: red; margin-bottom: 4px;"
                  class="invalid-feedback">
                  <div
                    *ngIf="check.controls.AccountPhone.errors.required || check.controls.AccountPhone.errors.isEmpty">
                    {{cmsListSection.required_validation_text }}</div>
                </div>
              </div>
              <div class="form-group" *ngIf="countrySelected === 'Uruguay' ">
                <input [readonly]="keepAccount" placeholder="{{ cmsListSection.form_locations_placeholder }}"
                  class="form-control input-card" formControlName="AccountSucursal" name="AccountSucursal"
                  (change)="editData($event)" style="color: #0A355E;"
                  [value]="lastPayment && lastPayment.Country ===countrySelected ? lastPayment.AccountSucursal:'' "
                  [ngClass]="{ 'is-invalid': errores_form && check.controls.AccountSucursal.errors }" />

                <div *ngIf="errores_form && check.controls.AccountSucursal.errors"
                  style="color: red; margin-bottom: 4px;" class="invalid-feedback">
                  <div *ngIf="check.controls.AccountSucursal.errors.required">{{ cmsListSection.required_validation_text
                    }}</div>
                </div>
              </div>
              <div class="form-group" *ngIf="countrySelected === 'Otros' || countrySelected === 'USA'">
                <input [readonly]="keepAccount" placeholder="{{ cmsListSection.form_tax_id_placeholder }}"
                  class="form-control input-card" formControlName="AccountTax" name="AccountTax"
                  (change)="editData($event)" style="color: #0A355E;"
                  [value]="lastPayment &&  lastPayment.Country ===countrySelected ? lastPayment.AccountTax:'' "
                  [ngClass]="{ 'is-invalid': errores_form && check.controls.AccountTax.errors && countrySelected === 'Otros' }"
                  invalidDNI />


                <div *ngIf="errores_form && check.controls.AccountTax.errors && countrySelected === 'Otros'"
                  style="color: red; margin-bottom: 4px;" class="invalid-feedback">
                  <div *ngIf="check.controls.AccountTax.errors.required || check.controls.AccountTax.errors.isEmpty">{{
                    cmsListSection.required_validation_text }}</div>
                </div>
              </div>

            </div>
            <div class="col-md-6 col-sm-6 col-12">
              <div *ngIf="countrySelected !== 'Otros' && countrySelected !== 'USA'" class="form-group">
                <select [disabled]="keepAccount" *ngIf="!(lastPayment && lastPayment.Country === countrySelected)"
                  class="form-control input-card " (change)="handleBank($event.target.value)"
                  [ngClass]="{ 'is-invalid': errores_form && bankSelected.length <2  }" style="color: #0A355E;">
                  <option value="" disabled selected>{{ cmsListSection.form_bank_placeholder }}</option>
                  <option [value]="banco" [disabled]="banco === cmsListSection.form_bank_placeholder"
                    *ngFor="let banco of banks">{{banco}}</option>
                </select>
                <select [disabled]="keepAccount" *ngIf="lastPayment && lastPayment.Country === countrySelected"
                  class="form-control input-card " (change)="handleBank($event.target.value)"
                  [ngClass]="{ 'is-invalid': errores_form && bankSelected.length <2  }" style="color: #0A355E;">
                  <option value="" disabled>{{ cmsListSection.form_bank_placeholder }}</option>
                  <option [value]="banco" [disabled]="banco === cmsListSection.form_bank_placeholder"
                    [selected]="banco.toUpperCase()===lastPayment.AccountBank.toUpperCase()"
                    *ngFor="let banco of banks">{{banco}}</option>
                </select>
                <div class="invalid-feedback" style="color: red; margin-bottom: 4px;">
                  {{ cmsListSection.required_validation_text }}
                </div>
              </div>
              <div class="form-group">
                <input [readonly]="keepAccount" placeholder="{{ cmsListSection.form_fullname_placeholder }}"
                  class="form-control input-card" (change)="editData($event)" formControlName="AccountName"
                  name="AccountName" style="color: #0A355E;"
                  [value]="lastPayment && lastPayment.Country ===countrySelected ? lastPayment.AccountName:'' "
                  [ngClass]="{ 'is-invalid': errores_form && check.controls.AccountName.errors }" />
                <div *ngIf="errores_form && check.controls.AccountName.errors" class="invalid-feedback"
                  style="color: red; margin-bottom: 4px;">
                  <div *ngIf="check.controls.AccountName.errors.required || check.controls.AccountName.errors.isEmpty">
                    {{ cmsListSection.required_validation_text }}</div>
                  <div *ngIf="check.controls.AccountName.errors.pattern">{{ cmsListSection.format_validation_text }}
                  </div>
                </div>
              </div>
              <div *ngIf="countrySelected === 'Otros' || countrySelected === 'USA'" class="form-group">
                <input [readonly]="keepAccount" placeholder="{{ cmsListSection.form_city_placeholder }}"
                  class="form-control input-card" (change)="editData($event)" formControlName="AccountCity"
                  name="AccountCity" style="color: #0A355E;"
                  [value]="lastPayment && lastPayment.Country ===countrySelected ? lastPayment.AccountCity:'' "
                  [ngClass]="{ 'is-invalid': errores_form && check.controls.AccountCity.errors }" />
                <div *ngIf="errores_form && check.controls.AccountCity.errors" style="color: red; margin-bottom: 4px;"
                  class="invalid-feedback">
                  <div *ngIf="check.controls.AccountCity.errors.required || check.controls.AccountCity.errors.isEmpty">
                    Este
                    campo es
                    requerido
                  </div>
                </div>
              </div>
              <div *ngIf="countrySelected === 'Otros' || countrySelected === 'USA'" class="form-group">
                <input [readonly]="keepAccount" placeholder="{{ cmsListSection.form_address_placeholder }}"
                  class="form-control input-card" (change)="editData($event)" formControlName="AccountAddress"
                  name="AccountAddress" style="color: #0A355E;"
                  [value]="lastPayment && lastPayment.Country ===countrySelected ? lastPayment.AccountAddress:'' "
                  [ngClass]="{ 'is-invalid': errores_form && check.controls.AccountAddress.errors }" />

                <div *ngIf="errores_form && check.controls.AccountAddress.errors"
                  style="color: red; margin-bottom: 4px;" class="invalid-feedback">
                  <div
                    *ngIf="check.controls.AccountAddress.errors.required || check.controls.AccountAddress.errors.isEmpty">
                    {{ cmsListSection.required_validation_text }}</div>
                </div>
              </div>
              <div class="form-group" *ngIf="countrySelected === 'Chile'">
                <input [readonly]="keepAccount" placeholder="RUT ej: 12345678-k" class="form-control input-card"
                  formControlName="AccountRUT" (change)="editData($event)" name="AccountRUT" style="color: #0A355E;"
                  [value]="lastPayment && lastPayment.Country ===countrySelected ? lastPayment.AccountRUT:'' "
                  [ngClass]="{ 'is-invalid': errores_form && check.controls.AccountRUT.errors }" />
                <div *ngIf="errores_form && check.controls.AccountRUT.errors" style="color: red; margin-bottom: 4px;"
                  class="invalid-feedback">
                  <div *ngIf="check.controls.AccountRUT.errors.required">{{ cmsListSection.required_validation_text }}
                  </div>
                  <div *ngIf="check.controls.AccountRUT.errors.pattern">{{ cmsListSection.format_validation_text }}
                  </div>
                  <div *ngIf="check.controls.AccountRUT.errors.invalidRutDv">{{ cmsListSection.ci_validation_text }}
                  </div>
                </div>
              </div>

              <div *ngIf="countrySelected === 'Peru'" class="form-group">
                <select class="form-control input-card "
                  [ngClass]="{ 'is-invalid': errores_form && typeDocumentPE.length <2 }"
                  (change)="handleAccountTypeDocumentPE($event.target.value)" style="color: #0A355E;">

                  <option value="" disabled selected>Tipo de documento</option>
                  <option value="RUC">RUC</option>
                  <option value="DNI">DNI</option>
                </select>

                <div class="invalid-feedback" style="color: red; margin-bottom: 4px;">
                  {{ cmsListSection.required_validation_text }}
                </div>

              </div>

              <div class="form-group" *ngIf="countrySelected === 'Peru' && typeDocumentPE === 'RUC' ">
                <input [readonly]="keepAccount" placeholder="{{ cmsListSection.placeholder_input_ruc }}"
                  class="form-control input-card" formControlName="AccountRUC" (change)="handlePeruDocType($event)"
                  name="AccountRUC" style="color: #0A355E;"
                  [value]="lastPayment && lastPayment.Country === countrySelected ? lastPayment.AccountRUC:'' "
                  [ngClass]="{ 'is-invalid': errores_form && check.controls.AccountRUC.errors }" />
                <div *ngIf="errores_form && check.controls.AccountRUC.errors" style="color: red; margin-bottom: 4px;"
                  class="invalid-feedback">
                  <div *ngIf="check.controls.AccountRUC.errors.required || check.controls.AccountRUC.errors.isEmpty">{{
                    cmsListSection.required_validation_text }}</div>
                  <div *ngIf="check.controls.AccountRUC.errors.invalidRuc">{{ cmsListSection.ruc_validation_text }}
                  </div>
                  <div
                    *ngIf="!check.controls.AccountRUC.errors.required && check.controls.AccountRUC.errors.invalidRucLength">
                    {{ msgValidationFields.RUC }}</div>
                </div>
              </div>

              <div class="form-group" *ngIf="countrySelected === 'Ecuador' ">
                <input [readonly]="keepAccount" placeholder="Número Swift" class="form-control input-card"
                  formControlName="AccountSwiftCode" (change)="editData($event)" name="AccountSwiftCode"
                  style="color: #0A355E;"
                  [value]="lastPayment && lastPayment.Country === countrySelected ? lastPayment.AccountSwiftCode:'' "
                  [ngClass]="{ 'is-invalid': errores_form && check.controls.AccountSwiftCode.errors }"
                  id="AccountSwiftCode" />
                <div *ngIf="errores_form && check.controls.AccountSwiftCode.errors"
                  style="color: red; margin-bottom: 4px;" class="invalid-feedback">
                  <div *ngIf="check.controls.AccountSwiftCode.errors.required">{{
                    cmsListSection.required_validation_text }}</div>

                </div>
              </div>

              <div class="form-group" *ngIf="countrySelected === 'Peru' && typeDocumentPE === 'DNI' ">
                <input [readonly]="keepAccount" placeholder="{{ cmsListSection.placeholder_input_dni }}"
                  class="form-control input-card" formControlName="AccountDNI" name="AccountDNI"
                  (change)="handlePeruDocType($event)" style="color: #0A355E;"
                  [value]="lastPayment && lastPayment.Country ===countrySelected ? lastPayment.AccountDNI:'' "
                  [ngClass]="{ 'is-invalid': errores_form && check.controls.AccountDNI.errors }" />

                <div *ngIf="errores_form && check.controls.AccountDNI.errors" style="color: red; margin-bottom: 4px;"
                  class="invalid-feedback">
                  <div *ngIf="check.controls.AccountDNI.errors.required || check.controls.AccountDNI.errors.isEmpty">{{
                    cmsListSection.required_validation_text }}</div>
                  <div *ngIf="check.controls.AccountDNI.errors.invalidDNI">El DNI es inválido</div>
                  <div
                    *ngIf="!check.controls.AccountDNI.errors.required && check.controls.AccountDNI.errors.invalidDNILength">
                    {{ msgValidationFields.DNI }}</div>
                </div>
              </div>

              <div class="form-group" *ngIf="countrySelected === 'Peru' && currencyCode === 'USD'">
                <input [readonly]="keepAccount" placeholder="Número Swift" class="form-control input-card"
                  formControlName="AccountSwiftCode" (change)="editData($event)" name="AccountSwiftCode"
                  style="color: #0A355E;"
                  [value]="lastPayment && lastPayment.Country === countrySelected && currencyCode === 'USD'? lastPayment.AccountSwiftCode:'' "
                  [ngClass]="{ 'is-invalid': errores_form && check.controls.AccountSwiftCode.errors }" />
                <div *ngIf="errores_form && check.controls.AccountSwiftCode.errors"
                  style="color: red; margin-bottom: 4px;" class="invalid-feedback">
                  <div *ngIf="check.controls.AccountSwiftCode.errors.required">{{
                    cmsListSection.required_validation_text }}</div>

                </div>
              </div>



              <div class="form-group" *ngIf="countrySelected === 'Argentina'">
                <input [readonly]="keepAccount" placeholder="{{ cmsListSection.form_cuit_cuil_placeholder }}"
                  class="form-control input-card" (change)="editData($event)" (click)="isEmpty($event)"
                  formControlName="AccountCUIT" name="AccountCUIT" style="color: #0A355E;"
                  [value]="lastPayment && lastPayment.Country ===countrySelected ? lastPayment.AccountCUIT:'' "
                  [ngClass]="{ 'is-invalid': errores_form && check.controls.AccountCUIT.errors }" />


                <div *ngIf="errores_form && check.controls.AccountCUIT.errors" style="color: red; margin-bottom: 4px;"
                  class="invalid-feedback">
                  <div *ngIf="check.controls.AccountCUIT.errors.required || check.controls.AccountCUIT.errors.isEmpty">
                    {{ cmsListSection.required_validation_text }}</div>
                </div>
              </div>
              <div class="form-group" *ngIf="countrySelected === 'Argentina'">
                <input [readonly]="keepAccount" placeholder="{{ cmsListSection.form_cbu_placeholder }}"
                  class="form-control input-card" (change)="editData($event)" formControlName="AccountCBU"
                  name="AccountCBU" style="color: #0A355E;"
                  [value]=" lastPayment && lastPayment.Country ===countrySelected ? lastPayment.AccountCBU:''"
                  [ngClass]="{ 'is-invalid': errores_form && check.controls.AccountCBU.errors }" />
                <div *ngIf="errores_form && check.controls.AccountCBU.errors" style="color: red; margin-bottom: 4px;"
                  class="invalid-feedback">
                  <div *ngIf="check.controls.AccountCBU.errors.required || check.controls.AccountCBU.errors.isEmpty">{{
                    cmsListSection.required_validation_text }}</div>
                  <div *ngIf="check.controls.AccountCBU.errors.invalidCBU">El CBU es inválido</div>
                  <div
                    *ngIf="!check.controls.AccountCBU.errors.required && check.controls.AccountCBU.errors.invalidCBULength">
                    {{ msgValidationFields.CBU }}</div>
                </div>
              </div>
              <div class="form-group" *ngIf="countrySelected === 'Colombia' || countrySelected === 'Ecuador'">
                <select [disabled]="keepAccount" *ngIf="!(lastPayment && lastPayment.Country === countrySelected)"
                  class="form-control input-card " (change)="handleDocumentNumber($event.target.value)"
                  style="color: #0A355E;" [ngClass]="{ 'is-invalid': errores_form && typeIdSelected.length < 2 }">
                  <option value="" disabled selected>{{ cmsListSection.form_account_id_document_type_placeholder }}
                  </option>
                  <option [value]="type.IdDocType"
                    [disabled]="type.IdDocType === cmsListSection.form_account_type_placeholder"
                    *ngFor="let type of idtypes">{{type.IdDocType}}</option>
                </select>
                <select [disabled]="keepAccount" *ngIf="lastPayment && lastPayment.Country === countrySelected"
                  class="form-control input-card " (change)="handleDocumentNumber($event.target.value)"
                  style="color: #0A355E;" [ngClass]="{ 'is-invalid': errores_form && typeIdSelected.length < 2  }">
                  <option value="" disabled>{{ cmsListSection.form_account_id_document_type_placeholder }}</option>
                  <option [value]="type.IdDocType"
                    [disabled]="type.IdDocType === cmsListSection.form_account_type_placeholder"
                    [selected]="type.IdDocType.toUpperCase()===lastPayment.AccountIDDocumentType.toUpperCase()"
                    *ngFor="let type of idtypes">{{type.IdDocType}}</option>
                </select>
                <div *ngIf="errores_form && check.controls.AccountIDDocumentType.errors"
                  style="color: red; margin-bottom: 4px;" class="invalid-feedback">
                  <div class="invalid-feedback" style="color: red; margin-bottom: 4px;">
                    {{ cmsListSection.required_validation_text }}
                  </div>
                  <div *ngIf="check.controls.AccountIDDocumentType.errors.required">{{
                    cmsListSection.required_validation_text }}</div>
                </div>
              </div>
              <div class="form-group" *ngIf="countrySelected === 'Colombia' || countrySelected === 'Ecuador'">
                <input [readonly]="keepAccount"
                  placeholder="{{ cmsListSection.form_account_id_document_number_placeholder }}"
                  (change)="editData($event)" class="form-control input-card" formControlName="AccountIDDocumentNumber"
                  style="color: #0A355E;" name="AccountIDDocumentNumber"
                  [value]="lastPayment && lastPayment.Country ===countrySelected ? lastPayment.AccountIDDocumentNumber:'' "
                  [ngClass]="{ 'is-invalid': errores_form && check.controls.AccountIDDocumentNumber.errors }"
                  id="AccountIDDocumentNumber" />
                <div *ngIf="errores_form && check.controls.AccountIDDocumentNumber.errors"
                  style="color: red; margin-bottom: 4px;" class="invalid-feedback">
                  <div
                    *ngIf="check.controls.AccountCBU.errors.required || check.controls.AccountIDDocumentNumber.errors.isEmpty">
                    {{ cmsListSection.required_validation_text }}</div>
                </div>
              </div>
              <div class="form-group" *ngIf="countrySelected === 'Brasil'">
                <input [readonly]="keepAccount" placeholder="{{ cmsListSection.form_account_cpf_placeholder }}"
                  class="form-control input-card" (change)="editData($event)" formControlName="AccountCPF"
                  name="AccountCPF" style="color: #0A355E;"
                  [value]="lastPayment &&lastPayment.Country === countrySelected ? lastPayment.AccountCPF:'' "
                  [ngClass]="{ 'is-invalid': errores_form && check.controls.AccountCPF.errors }" id="AccountCPF" />
                <div *ngIf="errores_form && check.controls.AccountCPF.errors" style="color: red; margin-bottom: 4px;"
                  class="invalid-feedback">
                  <div *ngIf="check.controls.AccountCPF.errors.required || check.controls.AccountCPF.errors.isEmpty">{{
                    cmsListSection.required_validation_text }}</div>
                  <div *ngIf="check.controls.AccountCPF.errors.invalidCPF">El CPF es inválido</div>
                  <div
                    *ngIf="!check.controls.AccountCPF.errors.required && check.controls.AccountCPF.errors.invalidCPFLength">
                    {{ msgValidationFields.CPF }}</div>
                </div>
              </div>
              <div class="form-group" *ngIf="countrySelected === 'Brasil'">
                <input [readonly]="keepAccount" placeholder="{{ cmsListSection.form_account_agency_placeholder }}"
                  (change)="editData($event)" class="form-control input-card"
                  [value]=" lastPayment && lastPayment.Country ===countrySelected ? lastPayment.AccountAgency:'' "
                  formControlName="AccountAgency" name="AccountAgency" style="color: #0A355E;"
                  [ngClass]="{ 'is-invalid': errores_form && check.controls.AccountAgency.errors  }"
                  id="AccountAgency" />
                <div *ngIf="errores_form && check.controls.AccountAgency.errors" style="color: red; margin-bottom: 4px;"
                  class="invalid-feedback">
                  <div
                    *ngIf="check.controls.AccountAgency.errors.required || check.controls.AccountAgency.errors.isEmpty">
                    {{ cmsListSection.required_validation_text }}</div>
                </div>
              </div>
              <div class="form-group" *ngIf="countrySelected === 'Brasil' && currencyCode === 'USD'">
                <input [readonly]="keepAccount" placeholder="Número Swift" class="form-control input-card"
                  formControlName="AccountSwiftCode" (change)="editData($event)" name="AccountSwiftCode"
                  style="color: #0A355E;"
                  [value]="lastPayment && lastPayment.Country === countrySelected && currencyCode === 'USD'? lastPayment.AccountSwiftCode:'' "
                  [ngClass]="{ 'is-invalid': errores_form && check.controls.AccountSwiftCode.errors }"
                  id="AccountSwiftCode" />
                <div *ngIf="errores_form && check.controls.AccountSwiftCode.errors"
                  style="color: red; margin-bottom: 4px;" class="invalid-feedback">
                  <div *ngIf="check.controls.AccountSwiftCode.errors.required">{{
                    cmsListSection.required_validation_text }}</div>

                </div>
              </div>

              <div class="form-group" *ngIf="countrySelected === 'Uruguay'">
                <input [readonly]="keepAccount" placeholder="RUT ej: 22604460" class="form-control input-card"
                  formControlName="AccountRUTUY" (change)="editData($event)" name="AccountRUTUY" style="color: #0A355E;"
                  [value]="lastPayment && lastPayment.Country ===countrySelected ? lastPayment.AccountRUTUY:'' "
                  [ngClass]="{ 'is-invalid': errores_form && check.controls.AccountRUTUY.errors }" />
                <div *ngIf="errores_form && check.controls.AccountRUTUY.errors" style="color: red; margin-bottom: 4px;"
                  class="invalid-feedback">
                  <div *ngIf="check.controls.AccountRUTUY.errors.required">{{ cmsListSection.required_validation_text }}
                  </div>
                  <div *ngIf="check.controls.AccountRUTUY.errors.invalidRutUy">{{ cmsListSection.ci_validation_text }}
                  </div>
                </div>
              </div>

              <div *ngIf="countrySelected === 'Uruguay'" class="form-group">
                <input [readonly]="keepAccount" placeholder="{{ cmsListSection.form_address_placeholder }}"
                  class="form-control input-card" (change)="editData($event)" formControlName="AccountAddress"
                  name="AccountAddress" style="color: #0A355E;"
                  [value]="lastPayment && lastPayment.Country ===countrySelected ? lastPayment.AccountAddress:'' "
                  [ngClass]="{ 'is-invalid': errores_form && check.controls.AccountAddress.errors }" />

                <div *ngIf="errores_form && check.controls.AccountAddress.errors"
                  style="color: red; margin-bottom: 4px;" class="invalid-feedback">
                  <div *ngIf="check.controls.AccountAddress.errors.required">{{ cmsListSection.required_validation_text
                    }}</div>
                </div>
              </div>

              <div class="form-group" *ngIf="countrySelected === 'Paraguay'">
                <input [readonly]="keepAccount" placeholder="Cedula de Identidad Ej: 4397241"
                  class="form-control input-card" formControlName="AccountCIPY" (change)="editData($event)"
                  name="AccountCIPY" style="color: #0A355E;"
                  [value]="lastPayment && lastPayment.Country ===countrySelected ? lastPayment.AccountCIPY:'' "
                  [ngClass]="{ 'is-invalid': errores_form && check.controls.AccountCIPY.errors }" />
                <div *ngIf="errores_form && check.controls.AccountCIPY.errors" style="color: red; margin-bottom: 4px;"
                  class="invalid-feedback">
                  <div *ngIf="check.controls.AccountCIPY.errors.required">{{ cmsListSection.required_validation_text }}
                  </div>
                  <div *ngIf="check.controls.AccountCIPY.errors.invalidRutUy">{{ cmsListSection.ci_validation_text }}
                  </div>
                </div>
              </div>

              <div *ngIf="countrySelected === 'Otros' || countrySelected === 'USA'" class="form-group">
                <input [readonly]="keepAccount" placeholder="{{ cmsListSection.form_bic_inter_placeholder }}"
                  class="form-control input-card" (change)="editData($event)" formControlName="AccountBankInter"
                  name="AccountBankInter" style="color: #0A355E;"
                  [value]="lastPayment && lastPayment.Country ===countrySelected ? lastPayment.AccountBankInter:'' "
                  [ngClass]="{ 'is-invalid': errores_form && check.controls.AccountBankInter.errors }"
                  id="AccountBankInter" />

                <div *ngIf="errores_form && check.controls.AccountBankInter.errors" style="color: red; margin-top: 1px;"
                  class="invalid-feedback">
                  <div
                    *ngIf="check.controls.AccountBankInter.errors.required || check.controls.AccountBankInter.errors.isEmpty">
                    {{ cmsListSection.required_validation_text }}</div>
                </div>
              </div>
              <div *ngIf="countrySelected === 'Otros' || countrySelected === 'USA'" class="form-group">
                <input [readonly]="keepAccount" placeholder="{{ cmsListSection.form_bic_benef_placeholder }}"
                  class="form-control input-card" (change)="editData($event)" formControlName="AccountBankBenef"
                  name="AccountBankBenef" style="color: #0A355E;"
                  [value]="lastPayment && lastPayment.Country ===countrySelected ? lastPayment.AccountBankBenef:'' "
                  [ngClass]="{ 'is-invalid': errores_form && check.controls.AccountBankBenef.errors }"
                  id="AccountBankBenef" />

                <div *ngIf="errores_form && check.controls.AccountBankBenef.errors" style="color: red; margin-top: 1px;"
                  class="invalid-feedback">
                  <div
                    *ngIf="check.controls.AccountBankBenef.errors.required || check.controls.AccountBankBenef.errors.isEmpty">
                    {{ cmsListSection.required_validation_text }}</div>
                </div>
              </div>

              <div *ngIf="countrySelected === 'Peru'" class="form-group">
                <select class="form-control input-card "
                  [ngClass]="{ 'is-invalid': errores_form && typeCurrency.length <2 }"
                  (change)="handleAccountTypeCurrency($event.target.value)" style="color: #0A355E;">

                  <option value="" disabled selected>Tipo de Moneda</option>
                  <option value="USD">USD</option>
                  <option value="PEN">PEN</option>
                </select>

                <div class="invalid-feedback" style="color: red; margin-bottom: 4px;">
                  {{ cmsListSection.required_validation_text }}
                </div>

              </div>

              <div>
                <div class="form-group">
                  <button [hidden]="!keepAccount" type="button" name="editAccount" class="secondary-btn mt-3"
                    (click)="resetAccount();">Editar</button>
                </div>
              </div>

            </div>
          </div>
        </form>
      </div>
      <div class="col-md-2 d-md-block d-none">
      </div>
    </div>
  </div>
</div>