<footer *ngIf="itemsFooter$ | async as itemsFooter">


    <div id="info" class="container-fluid px-0 pb-3">


        <div class="container">


            <div class="row align-items-center">


                <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12 mb-xl-0 mb-lg-0 mb-md-0 mb-sm-0 mb-3 text-center payments-footer">
                    <div class="text-left">
                        <p class="mb-0">{{cmsMainSection.payment_title.value}}</p>
                        <div id="paymentImagesContainer" class="p-2 vertical-align" *ngFor="let medioPago of itemsFooter.items[0].payment_img.value">
                            <img src="{{medioPago.url}}" class="webpay-redcompra paymentImg" />
                        </div>
                    </div>
                </div>



                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-8 offset-xl-0 offset-lg-0 offset-md-0 offset-sm-0 offset-2 rss-footer text-right">
                    <div class="text-center d-inline-block">
                        <p class="mb-0">{{cmsMainSection.rrss_title.value}}</p>
                        <div class="d-block">

                            <div *ngFor="let rs of itemsFooter.items[0].rrss__links; index as i" id="rrss-images">
                                <a *ngIf="rsClass$ | async as rsClass" href="{{rs.url.value}}" class="d-inline-block"><i [class]="rsClass[i]"></i></a>
                            </div>

                        </div>
                    </div>
                </div>


            </div>
        </div>
    </div>



    <div class="container mt-4">
        <div class="row accordion" id="footer_cols">


            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">


                <h5 class="d-xl-none d-lg-none d-md-none d-sm-none cursor-pointer collapsed" data-toggle="collapse"
                    data-target="#footer_col_1" aria-expanded="true" aria-controls="footer_col_1">
                    {{cmsMainSection.col_title_1.value}} <i class="fas fa-plus-square float-right"></i>
                </h5>


                <h5 class="d-xl-block d-lg-block d-md-block d-sm-block d-none">
                    {{cmsMainSection.col_title_1.value}}
                </h5>


                <div id="footer_col_1" class="collapse d-xl-block d-lg-block d-md-block d-sm-block" data-parent="#footer_cols">

                    <ul class="list-unstyled text-small">
                        <li *ngFor="let item of itemsFooter.items[0].col_links_1">
                            <a href="{{item.url.value}}" *ngIf="item.value.value"><span class="hover-border-bottom-left">{{item.value.value}}</span></a>
                        </li>
                    </ul>

                </div>



            </div>
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                <h5 class="d-xl-none d-lg-none d-md-none d-sm-none cursor-pointer collapsed" data-toggle="collapse"
                    data-target="#footer_col_2" aria-expanded="true" aria-controls="footer_col_2">
                    {{cmsMainSection.col_title_2.value}} <i class="fas fa-plus-square float-right"></i>
                </h5>
                <h5 class="d-xl-block d-lg-block d-md-block d-sm-block d-none">
                    {{cmsMainSection.col_title_2.value}}
                </h5>

                <div id="footer_col_2" class="collapse d-xl-block d-lg-block d-md-block d-sm-block" data-parent="#footer_cols">

                    <ul class="list-unstyled text-small">
                        <li *ngFor="let item of itemsFooter.items[0].col_links_2">
                            <a href="{{item.url.value}}" *ngIf="item.value.value"><span class="hover-border-bottom-left">{{item.value.value}}</span></a>
                        </li>
                    </ul>
                </div>

            </div>
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                <h5 class="d-xl-none d-lg-none d-md-none d-sm-none cursor-pointer collapsed" data-toggle="collapse"
                    data-target="#footer_col_3" aria-expanded="true" aria-controls="footer_col_3">
                    {{cmsMainSection.col_title_3.value}} <i class="fas fa-plus-square float-right"></i>
                </h5>
                <h5 class="d-xl-block d-lg-block d-md-block d-sm-block d-none">
                    {{cmsMainSection.col_title_3.value}}
                </h5>


                <div id="footer_col_3" class="collapse d-xl-block d-lg-block d-md-block d-sm-block" data-parent="#footer_cols">

                    <ul class="list-unstyled text-small">
                        <li *ngFor="let item of itemsFooter.items[0].col_links_3">
                            <a href="{{item.url.value}}" *ngIf="item.value.value"><span class="hover-border-bottom-left">{{item.value.value}}</span></a>
                        </li>
                    </ul>
                </div>


            </div>
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                <h5 class="d-xl-none d-lg-none d-md-none d-sm-none cursor-pointer collapsed" data-toggle="collapse"
                    data-target="#footer_col_4" aria-expanded="true" aria-controls="footer_col_4">
                    {{cmsMainSection.col_title_4.value}} <i class="fas fa-plus-square float-right"></i>
                </h5>
                <h5 class="d-xl-block d-lg-block d-md-block d-sm-block d-none">
                    {{cmsMainSection.col_title_4.value}}
                </h5>

                <div id="footer_col_4" class="collapse d-xl-block d-lg-block d-md-block d-sm-block" data-parent="#footer_cols">

                    <ul class="list-unstyled text-small">
                        <li *ngFor="let item of itemsFooter.items[0].col_links_4">
                            <a href="{{item.url.value}}" *ngIf="item.value.value"><span class="hover-border-bottom-left">{{item.value.value}}</span></a>
                        </li>
                    </ul>
                </div>

            </div>
        </div>
    </div>



    <div id="copyright" class="container-fluid px-0">
        <div class="separator-container py-3">
            <hr class="red border-top-8px m-0">
        </div>
        <div class="container pb-3">
            <div class="row align-items-center">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <p class="mb-0 text-left">{{itemsFooter.items[0].copyright_text.value}}</p>
                </div>
                <div class="col-xl-2 col-lg-2 col-md-3 col-sm-3 col-6 offset-xl-4 offset-lg-4 offset-md-3 offset-sm-3 offset-3 text-right">
                    <img src="https://{{baseUrl}}/assets/jetsmart-logo-colored.svg?ver=0.1" alt="JetSmart Logo" title="JetSmart Logo"
                        class="logo-jetsmart-footer img-fluid">
                </div>
            </div>
        </div>
    </div>



</footer>
