import {Component, OnInit, OnChanges, SimpleChanges, Input, Output, EventEmitter, ViewChild, ElementRef} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { PitValidators } from '../validators/pit-validators';
import { RefundService } from '../refund.service';
import { Payment } from '../../types/formPassengers';
import { COUNTRIES, LENGTH_FIELDS, getMsgLengthFields} from '../../constants/forms';
import {NgSelectComponent} from '@ng-select/ng-select';
import {environment} from '../../environments/environment';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit, OnChanges  {
  mediosPago: string[] = [];
  idtypes: any [] = [];
  countries: string [] = COUNTRIES;
  banks: string[] = [];
  devolucionForm: UntypedFormGroup;
  @Input() errores_form: boolean;
  @Input() lastPayment: Payment;
  @Input() lastPaymentDummy: Payment;
  loaded: boolean = false;
  countrySelected: string;
  @Input() currencyCode: string;
  accountSelected: string = '-';
  bankSelected: string = '-';
  typeIdSelected: string = '-';
  typeCurrency: string = '-';
  typeDocumentPE: string = '-';
  @Input() cmsListSection;
  payments: Payment;
  keepAccount = false;
  @Input() getPayments: number = 0;
  @Output() payments_submitted: EventEmitter<UntypedFormGroup> = new EventEmitter();
  @Output() countryChild: EventEmitter<string> = new EventEmitter();
  @Output() changeError: EventEmitter<string> = new EventEmitter();
  msgValidationFields: any = {};
  controlValidators = {
    'AccountType':[Validators.required, Validators.minLength(3)], 
    'AccountBank':[Validators.required, Validators.minLength(3)], 
    'AccountNumber':[Validators.required, Validators.minLength(4), Validators.pattern('^[0-9]+$')], 
    'AccountRUT':[Validators.required, PitValidators.rutDv, Validators.minLength(2)], 
    'AccountRUC':[Validators.required, Validators.minLength(11),Validators.maxLength(11), PitValidators.ValidRuc, PitValidators.isEmpty],
    'AccountDNI': [Validators.required, Validators.minLength(8), PitValidators.ValidDNI, PitValidators.isEmpty],
    'AccountCCI':[Validators.required, Validators.minLength(20), Validators.maxLength(20), PitValidators.ValidCCI],
    'AccountRUTUY':[Validators.required, Validators.minLength(8), PitValidators.validRutUy],
    'AccountCIPY' :[Validators.required, Validators.minLength(7)],
    'AccountSwiftCode':[Validators.required, Validators.minLength(3)], 
    'AccountEmail':[Validators.required, Validators.minLength(3), Validators.pattern('[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}')], 
    'AccountName':[Validators.required, Validators.pattern('^[A-z\u00C0-\u00ff\\s\'\\.-\\/!$%\\^&\\*{}=\\-_`~()]+$'), Validators.minLength(2), PitValidators.isEmpty], 
    'AccountSucursal':[Validators.required, Validators.minLength(2), PitValidators.isEmpty], 
    'AccountCUIT':[Validators.required, Validators.minLength(4), PitValidators.isEmpty], 
    'AccountCBU':[Validators.required, Validators.minLength(22), Validators.maxLength(22), PitValidators.isEmpty, PitValidators.validateCBU], 
    'AccountIDDocumentType':[Validators.required, Validators.minLength(2)], 
    'AccountIDDocumentNumber':[Validators.required, Validators.minLength(3), PitValidators.isEmpty], 
    'AccountPhone':[Validators.required, Validators.minLength(4), PitValidators.isEmpty], 
    'AccountCPF':[Validators.required, Validators.minLength(11),Validators.maxLength(11), PitValidators.isEmpty, PitValidators.validateCPF], 
    'AccountIBAN':[PitValidators.validIBAN], 
    'AccountAgency':[Validators.required, Validators.minLength(2), PitValidators.isEmpty], 
    'AccountTax':[Validators.required, Validators.minLength(2), PitValidators.isEmpty], 
    'AccountCountry':[Validators.required, Validators.minLength(3), PitValidators.isEmpty], 
    'AccountBankBenef':[Validators.required, Validators.minLength(4), PitValidators.isEmpty], 
    'AccountBankInter':[Validators.required, Validators.minLength(4), PitValidators.isEmpty], 
    'AccountCity':[Validators.required, Validators.minLength(4), PitValidators.isEmpty], 
    'AccountAddress':[Validators.required, Validators.minLength(4), PitValidators.isEmpty,],
    'AccountTypeCurrency':[Validators.required],
  };
  baseUrl = environment.url;

  
  constructor(private formBuilder: UntypedFormBuilder, private reservaService: RefundService) {
  }
  ngOnInit() {
    this.devolucionForm = this.formBuilder.group({});

    //Se setean valores de validaciones desde kentico
    for (const fieldName in LENGTH_FIELDS) {
      if (LENGTH_FIELDS.hasOwnProperty(fieldName)) {
          const field = LENGTH_FIELDS[fieldName];
          this.msgValidationFields[fieldName] = getMsgLengthFields(field.name, field.length, this.cmsListSection.msg_default_length);
      }
  }
    
    this.currencyCode = this.currencyCode
    console.log('este es el currencyCode: ',this.currencyCode )
/*     if ( !(this.countries[0] == this.cmsListSection.country_placeholder) ){

      this.countries.unshift(this.cmsListSection.country_placeholder)
    }  */
    console.log('countries unshift', this.countries)
    if (this.lastPayment) {      
      this.keepAccount = true;
      this.countrySelected = this.lastPayment.Country;
      this.countryChild.emit(this.countrySelected);
      this.bankSelected = this.lastPayment.AccountBank;
      this.accountSelected = this.lastPayment.AccountType;
      this.typeIdSelected = this.lastPayment.AccountIDDocumentType;
    } else {
     this.countrySelected = this.cmsListSection.country_placeholder;
    }
    Promise.all([
      this.listBanks(this.countrySelected), this.getAccountTypes(this.countrySelected), this.listTypesCol(this.countrySelected)
    ]).then(() => {
      this.buildForm();
      this.countryChild.emit(this.countrySelected);
    });
  }
  ngOnChanges(changes: SimpleChanges) {
    console.log('voy a cambiar');
    if (changes['getPayments'] ) {
      this.getPayments = 0;
    }
  }
  buildForm() {
    console.log('this.countrySelected: ',this.countrySelected)
    const commonConditions = () => {
      return ((this.lastPayment && this.lastPayment.Country === this.countrySelected) ? true : false);
    };
    this.devolucionForm.addControl('0', new UntypedFormGroup({
      Countries: new UntypedFormControl(this.countrySelected , [Validators.required, Validators.minLength(3)]),
      AccountType: new UntypedFormControl(commonConditions() ? this.lastPayment.AccountType : '', [Validators.required, Validators.minLength(3)]),
      AccountBank: new UntypedFormControl(commonConditions() ? this.lastPayment.AccountBank : '', [Validators.required, Validators.minLength(3)]),
      AccountNumber: new UntypedFormControl(commonConditions() ? this.lastPayment.AccountNumber : '', [Validators.required, Validators.minLength(4),
												Validators.pattern('^[0-9]+$')]),
      AccountRUT: new UntypedFormControl(commonConditions() ? this.lastPayment.AccountRUT : '', /* [Validators.required, PitValidators.rutDv, Validators.minLength(2)] */),
      AccountRUC: new UntypedFormControl(commonConditions() ? this.lastPayment.AccountRUC : '', [Validators.required, Validators.minLength(11),Validators.maxLength(11), PitValidators.ValidRuc, PitValidators.isEmpty]),
      AccountDNI: new UntypedFormControl(commonConditions() ? this.lastPayment.AccountDNI : '', [Validators.required, Validators.minLength(8), PitValidators.ValidDNI, PitValidators.isEmpty]), //this.devolucionForm.get('AccountRUC').value.length > 0 ? true : false
      AccountCCI: new UntypedFormControl(commonConditions() ? this.lastPayment.AccountCCI : '', [Validators.required, Validators.minLength(20), Validators.maxLength(20), PitValidators.ValidCCI]),
      AccountRUTUY: new UntypedFormControl(commonConditions() ? this.lastPayment.AccountRUC : '', [Validators.required, Validators.minLength(8), PitValidators.validRutUy]),
      AccountCIPY: new UntypedFormControl(commonConditions() ? this.lastPayment.AccountCIPY : '', [Validators.required, Validators.minLength(7)]),
      AccountSwiftCode: new UntypedFormControl(commonConditions() ? this.lastPayment.AccountSwiftCode : '', [Validators.required, Validators.minLength(3)]),
      AccountEmail: new UntypedFormControl(commonConditions() ? this.lastPayment.AccountEmail : '', [Validators.required, Validators.minLength(3),
											      Validators.pattern('[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}')]),
      AccountName: new UntypedFormControl(commonConditions() ? this.lastPayment.AccountName : '', [Validators.required,
											    Validators.pattern('^[A-z\u00C0-\u00ff\\s\'\\.-\\/!$%\\^&\\*{}=\\-_`~()]+$'), Validators.minLength(2), PitValidators.isEmpty]),
      AccountSucursal: new UntypedFormControl(commonConditions() ? this.lastPayment.AccountSucursal : '' , [Validators.required, Validators.minLength(2), PitValidators.isEmpty]),
      AccountCUIT: new UntypedFormControl(commonConditions() ? this.lastPayment.AccountCUIT : '', [Validators.required, Validators.minLength(4), PitValidators.isEmpty]),
      AccountCBU: new UntypedFormControl(commonConditions() && this.lastPayment.AccountCBU && this.lastPayment.AccountCBU !== '-' ? this.lastPayment.AccountCBU : '', [Validators.required, Validators.minLength(22),Validators.maxLength(22), PitValidators.isEmpty, PitValidators.validateCBU]),
      AccountIDDocumentType: new UntypedFormControl(commonConditions() ? this.lastPayment.AccountIDDocumentType : '', [Validators.required, Validators.minLength(2)]),
      AccountIDDocumentNumber: new UntypedFormControl(commonConditions() ? this.lastPayment.AccountIDDocumentNumber : '', [Validators.required, Validators.minLength(3), PitValidators.isEmpty]),
      AccountPhone: new UntypedFormControl(commonConditions() ? this.lastPayment.AccountPhone : '', [Validators.required, Validators.minLength(4), PitValidators.isEmpty]),
      AccountCPF: new UntypedFormControl(commonConditions() ? this.lastPayment.AccountCPF : '', [Validators.required, Validators.minLength(11), Validators.maxLength(11), PitValidators.isEmpty, PitValidators.validateCPF]),
      AccountIBAN: new UntypedFormControl(commonConditions() ? this.lastPayment.AccountIBAN : '', [PitValidators.validIBAN]),
      AccountAgency: new UntypedFormControl(commonConditions() ? this.lastPayment.AccountAgency : '', [Validators.required, Validators.minLength(2), PitValidators.isEmpty]),
      AccountTax: new UntypedFormControl(commonConditions() ? this.lastPayment.AccountTax : '', [Validators.required, Validators.minLength(2), PitValidators.isEmpty]),
      AccountCountry: new UntypedFormControl(commonConditions() ? this.lastPayment.AccountCountry : '', [Validators.required, Validators.minLength(3), PitValidators.isEmpty]),
      AccountBankBenef: new UntypedFormControl(commonConditions() ? this.lastPayment.AccountBankBenef : '', [Validators.required, Validators.minLength(4), PitValidators.isEmpty]),
      AccountBankInter: new UntypedFormControl(commonConditions() ? this.lastPayment.AccountBankInter : '', [Validators.required, Validators.minLength(4), PitValidators.isEmpty]),
      AccountCity: new UntypedFormControl(commonConditions() ? this.lastPayment.AccountCity : '', [Validators.required, Validators.minLength(4), PitValidators.isEmpty]),
      AccountAddress: new UntypedFormControl(commonConditions() ? this.lastPayment.AccountAddress : '', [Validators.required, Validators.minLength(4), PitValidators.isEmpty]),
      keepAccount: new UntypedFormControl(this.keepAccount),
      AccountTypeCurrency: new UntypedFormControl(this.typeCurrency, [Validators.required]),
    }));

    
    console.log('last payment es', this.lastPayment);
    console.log('va a emitir ', this.devolucionForm);

    if(this.keepAccount == false) this.resetAccount()
        
    this.payments_submitted.emit(this.devolucionForm);
    this.loaded = true;
    // this.errores_form = true;
  }
  get check() {

    return this.devolucionForm.controls[0];
  }
  
  clearFormValidators() {
    for(let n in this.controlValidators) {
      this.devolucionForm.controls['0'].get(n).clearValidators();
      this.devolucionForm.controls['0'].get(n).updateValueAndValidity();
    }
  }
  
  setFormValidators() {
    for(let n in this.controlValidators) {
      this.devolucionForm.controls['0'].get(n).setValidators(this.controlValidators[n]);
      this.devolucionForm.controls['0'].get(n).updateValueAndValidity();      
      this.devolucionForm.controls['0'].get(n).setValue('');
    }
    this.lastPayment = this.lastPaymentDummy;
 }

  isEmpty(inputTxt: String){
    console.log()
    return this.devolucionForm.value[0].AccountCUIT.replace(' ','') === "";

  }
  
  editData(e: any) {
    console.log('en edit llego ', e);

    let AccountName = this.devolucionForm.controls['0'].get('AccountName');
    this.devolucionForm.controls['0'].get('AccountName').setValue(AccountName.value.trim());
    if(!AccountName.value.trim()) this.devolucionForm.controls['0'].get('AccountName').setValue('');

    let AccountSucursal = this.devolucionForm.controls['0'].get('AccountSucursal');
    this.devolucionForm.controls['0'].get('AccountSucursal').setValue(AccountSucursal.value.trim());
    if(!AccountSucursal.value.trim()) this.devolucionForm.controls['0'].get('AccountSucursal').setValue('');

    let AccountCUIT = this.devolucionForm.controls['0'].get('AccountCUIT');
    this.devolucionForm.controls['0'].get('AccountCUIT').setValue(AccountCUIT.value.trim());
    if(!AccountCUIT.value.trim()) this.devolucionForm.controls['0'].get('AccountCUIT').setValue('');

    let AccountIDDocumentNumber = this.devolucionForm.controls['0'].get('AccountIDDocumentNumber');
    this.devolucionForm.controls['0'].get('AccountIDDocumentNumber').setValue(AccountIDDocumentNumber.value.trim());
    if(!AccountIDDocumentNumber.value.trim()) this.devolucionForm.controls['0'].get('AccountIDDocumentNumber').setValue('');

    let AccountPhone = this.devolucionForm.controls['0'].get('AccountPhone');
    this.devolucionForm.controls['0'].get('AccountPhone').setValue(AccountPhone.value.trim());
    if(!AccountPhone.value.trim()) this.devolucionForm.controls['0'].get('AccountPhone').setValue('');

    let AccountCPF = this.devolucionForm.controls['0'].get('AccountCPF');
    this.devolucionForm.controls['0'].get('AccountCPF').setValue(AccountCPF.value.trim());
    if(!AccountCPF.value.trim()) this.devolucionForm.controls['0'].get('AccountCPF').setValue('');
    AccountCPF.enable()
    AccountCPF.setValidators([Validators.required, Validators.minLength(11),Validators.maxLength(11), PitValidators.validateCPF])

    let AccountAgency = this.devolucionForm.controls['0'].get('AccountAgency');
    this.devolucionForm.controls['0'].get('AccountAgency').setValue(AccountAgency.value.trim());
    if(!AccountAgency.value.trim()) this.devolucionForm.controls['0'].get('AccountAgency').setValue('');


    let AccountTax = this.devolucionForm.controls['0'].get('AccountTax');
    this.devolucionForm.controls['0'].get('AccountTax').setValue(AccountTax.value.trim());
    if(!AccountTax.value.trim()) this.devolucionForm.controls['0'].get('AccountTax').setValue('');
    
    
    let AccountCountry = this.devolucionForm.controls['0'].get('AccountCountry');
    this.devolucionForm.controls['0'].get('AccountCountry').setValue(AccountCountry.value.trim());
    if(!AccountCountry.value.trim()) this.devolucionForm.controls['0'].get('AccountCountry').setValue('');


    let AccountBankBenef = this.devolucionForm.controls['0'].get('AccountBankBenef');
    this.devolucionForm.controls['0'].get('AccountBankBenef').setValue(AccountBankBenef.value.trim());
    if(!AccountBankBenef.value.trim()) this.devolucionForm.controls['0'].get('AccountBankBenef').setValue('');

    let AccountBankInter = this.devolucionForm.controls['0'].get('AccountBankInter');
    this.devolucionForm.controls['0'].get('AccountBankInter').setValue(AccountBankInter.value.trim());
    if(!AccountBankInter.value.trim()) this.devolucionForm.controls['0'].get('AccountBankInter').setValue('');

    let AccountCity = this.devolucionForm.controls['0'].get('AccountCity');
    this.devolucionForm.controls['0'].get('AccountCity').setValue(AccountCity.value.trim());
    if(!AccountCity.value.trim()) this.devolucionForm.controls['0'].get('AccountCity').setValue('');

    let AccountAddress = this.devolucionForm.controls['0'].get('AccountAddress');
    this.devolucionForm.controls['0'].get('AccountAddress').setValue(AccountAddress.value.trim());
    if(!AccountAddress.value.trim()) this.devolucionForm.controls['0'].get('AccountAddress').setValue('');

    let AccountEmail = this.devolucionForm.controls['0'].get('AccountEmail');
    this.devolucionForm.controls['0'].get('AccountEmail').setValue(AccountEmail.value.trim());
    if(!AccountEmail.value.trim()) this.devolucionForm.controls['0'].get('AccountEmail').setValue('');


    let AccountCBU = this.devolucionForm.controls['0'].get('AccountCBU');
    this.devolucionForm.controls['0'].get('AccountCBU').setValue(AccountCBU.value.trim());
    if(!AccountCBU.value.trim()) this.devolucionForm.controls['0'].get('AccountCBU').setValue('');
    AccountCBU.enable()
    AccountCBU.setValidators([Validators.required, Validators.minLength(22),Validators.maxLength(22), PitValidators.validateCBU])

    let AccountNumber = this.devolucionForm.controls['0'].get('AccountNumber');
    this.devolucionForm.controls['0'].get('AccountNumber').setValue(AccountNumber.value.trim());
    if(!AccountNumber.value.trim()) this.devolucionForm.controls['0'].get('AccountNumber').setValue('');

    let AccountRUT = this.devolucionForm.controls['0'].get('AccountRUT');
    this.devolucionForm.controls['0'].get('AccountRUT').setValue(AccountRUT.value.trim());
    if(!AccountRUT.value.trim()) this.devolucionForm.controls['0'].get('AccountRUT').setValue('');

    let AccountIBAN = this.devolucionForm.controls['0'].get('AccountIBAN');
    this.devolucionForm.controls['0'].get('AccountIBAN').setValue(AccountIBAN.value.trim());
    if(!AccountIBAN.value.trim()) this.devolucionForm.controls['0'].get('AccountIBAN').setValue('');

    let AccountCIPY = this.devolucionForm.controls['0'].get('AccountCIPY');
    this.devolucionForm.controls['0'].get('AccountCIPY').setValue(AccountCIPY.value.trim());
    if(!AccountCIPY.value.trim()) this.devolucionForm.controls['0'].get('AccountCIPY').setValue('');

    let AccountCCI = this.devolucionForm.controls['0'].get('AccountCCI');
    this.devolucionForm.controls['0'].get('AccountCCI').setValue(AccountCCI.value.trim());
    if(!AccountCCI.value.trim()) this.devolucionForm.controls['0'].get('AccountCCI').setValue('');

    let AccountRUTUY = this.devolucionForm.controls['0'].get('AccountRUTUY');
    this.devolucionForm.controls['0'].get('AccountRUTUY').setValue(AccountRUTUY.value.trim());
    if(!AccountRUTUY.value.trim()) this.devolucionForm.controls['0'].get('AccountRUTUY').setValue('');



    this.devolucionForm.value[0].AccountBank = this.bankSelected;
    this.devolucionForm.value[0].AccountType = this.accountSelected;
    this.devolucionForm.value[0].AccountIDDocumentType = this.typeIdSelected;
    this.devolucionForm.value[0].AccountTypeCurrency = this.typeCurrency;
    this.payments_submitted.emit(this.devolucionForm);
  }

  handlePeruDocType(e: any) {

    const ctrlDNI = this.devolucionForm.controls['0'].get('AccountDNI') 
    const ctrlRUC = this.devolucionForm.controls['0'].get('AccountRUC')

    this.devolucionForm.controls['0'].get('AccountDNI').setValue(ctrlDNI.value.trim());
    if(!ctrlDNI.value.trim()) this.devolucionForm.controls['0'].get('AccountDNI').setValue('');

    this.devolucionForm.controls['0'].get('AccountRUC').setValue(ctrlRUC.value.trim());
    if(!ctrlRUC.value.trim()) this.devolucionForm.controls['0'].get('AccountRUC').setValue('');

    console.log('ctrlRUC:  ',ctrlRUC)
    if (ctrlDNI.value.length > 0) {
      ctrlRUC.disable();
      ctrlRUC.clearValidators();
    } else if (ctrlRUC.value.length > 0) {
      ctrlDNI.disable();
      ctrlDNI.clearValidators();
    } else {
      ctrlDNI.enable()
      ctrlDNI.setValidators([Validators.required, Validators.minLength(8), Validators.maxLength(8), PitValidators.ValidDNI, PitValidators.isEmpty])
      ctrlRUC.enable()
      ctrlRUC.setValidators([Validators.required, Validators.minLength(11), Validators.maxLength(11), PitValidators.ValidRuc, PitValidators.isEmpty])
    }

    this.devolucionForm.value[0].AccountBank = this.bankSelected
    this.devolucionForm.value[0].AccountType = this.accountSelected;
  }

  verifyForm() {

    console.log('verifiyForm >>> ', this.devolucionForm);

    if (this.loaded && this.devolucionForm !== undefined && this.devolucionForm.valid && this.devolucionForm.value[0].AccountBank !== this.cmsListSection.form_bank_placeholder
      && this.devolucionForm.value[0].AccountType !== this.cmsListSection.form_account_type_placeholder && this.devolucionForm.value[0].Countries !== this.cmsListSection.country_placeholder ) {
      return true;
    } else {
      return false;
    }
  }
  handleCountry(country: any) {
    console.log('country es', country );
    console.log('formulario de devoluciones es ', this.devolucionForm);
    this.loaded = false;
    this.countrySelected = country;
    this.mediosPago = [];
    this.banks = [];
    this.accountSelected = '-';
    this.bankSelected = '-';
    this.typeIdSelected = '-';
    this.changeError.emit();
    if ((country !== 'Otros' && country !== 'USA') && country !== this.cmsListSection.country_placeholder) {
      console.log(' no entro con country en otros');
      Promise.all([
        this.listBanks(this.countrySelected), this.getAccountTypes(this.countrySelected), this.listTypesCol(this.countrySelected)
        , this.devolucionForm.removeControl('0')
      ]).then(() => {
        this.buildForm();
        this.countryChild.emit(country);
        if ( this.lastPayment && country === this.lastPayment.Country) {
          this.accountSelected = this.lastPayment.AccountType;
          this.bankSelected = this.lastPayment.AccountBank;
          this.typeIdSelected = this.lastPayment.AccountIDDocumentType;
        }
        this.loaded = true;
      });
    } else {
      console.log('entro con country en otros o usa');
      this.devolucionForm.removeControl('0');
      this.buildForm();
      this.countryChild.emit(country);
      this.loaded = true;
    }
  }
  handleBank(bank: string) {
    this.bankSelected = bank;
    this.devolucionForm.value[0].AccountBank = bank;
    this.payments_submitted.emit(this.devolucionForm);
  }
  handleAccount(account: string) {
    this.accountSelected = account;
    this.devolucionForm.value[0].AccountType = account;
    this.payments_submitted.emit(this.devolucionForm);
  }
  handleAccountTypeCurrency(typeCurrency: string) {
    console.log('typeCurrency >>>> ',typeCurrency);
    this.typeCurrency = typeCurrency;
    this.devolucionForm.value[0].AccountTypeCurrency = typeCurrency;
    this.payments_submitted.emit(this.devolucionForm);
  }
  handleAccountTypeDocumentPE(typeDocumentPE: string) {
    console.log('typeDocumentPE >>>> ',typeDocumentPE);
    this.typeDocumentPE = typeDocumentPE;
    this.devolucionForm.value[0].AccountTypeDocumentPE = typeDocumentPE;
    this.payments_submitted.emit(this.devolucionForm);
  }
  handleDocumentNumber(type: string) {
    this.typeIdSelected = type;
    this.devolucionForm.value[0].AccountIDDocumentType = type;
    this.payments_submitted.emit(this.devolucionForm);
  }
  muestraDatos() {
    console.log('lastpayment es ', this.lastPayment);
    console.log('devolucion form', this.devolucionForm);
    console.log(this.countrySelected);
    this.payments_submitted.emit(this.devolucionForm);

  }
  sendPayments() {
    if (!this.verifyForm()) {
      this.errores_form = true;
      return;
    } else {
      this.errores_form = false;
      this.payments_submitted.emit(this.devolucionForm);
    }
  }
  listBanks(country: string): Promise<any> {
    console.log('country es', country);
    return new Promise<any>((resolve, reject) => {
      console.log('ciudad es ', country );
      this.reservaService.listBanks(country)
        .then(resp => {
          if (resp) {
            console.log('respondio bancos');
            resp.map(element => {
              this.banks.push(element.BankName);
            });
          }
          resolve(resp);
        });
    });
  }
  listTypesCol(country: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.reservaService.listTypesCol(country)
        .then(resp => {
          this.idtypes = resp ;
          resolve(resp);
        });
    });
  }
  getAccountTypes(country: string): Promise<any> {
    console.log('country es', country );
    return new Promise<any>((resolve, reject) => {
      this.reservaService.getAccountType(country)
        .then(resp => {
         // this.mediosPago = [this.cmsListSection.form_account_type_placeholder];
          if (resp) {
            console.log('medios pago', resp);
            resp.map(element => {
              this.mediosPago.push(element.AccountType);
            });
          }
          resolve(resp);
        });
    });
  }
  resetAccount() {
    this.keepAccount = false;
    this.setFormValidators();
    this.devolucionForm.controls['0'].get('keepAccount').setValue(false);
  }
}
